import { useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SetlistView from "../components/SetlistView"
import { timeToSeconds } from "../util/util"
import { setCurrentSetlist, setCurrentSetlistFiles, setCurrentSetlistItem, setCurrentPath, } from "../redux/actions/currentInfoActions"
import spinner from "../images/loading.svg"

const SetlistScreen = ({ match, history }) => {
  const { fools, loading, saving, error } = useSelector((state) => state.fools)
  const { currentlyEditing } = useSelector(
    (state) => state.currentInfo
  )
  const dispatch = useDispatch()

  // const [counter, setCounter] = useState(0)
  useEffect(() => {
    dispatch(setCurrentSetlist(null))
    dispatch(setCurrentSetlistFiles(null))
    dispatch(setCurrentSetlistItem(null))
    dispatch(setCurrentPath("setlists"))
  }, [dispatch])

  const getFiles = (setlist) => {
    if (setlist.setlistItems.length > 0) {
      const files = setlist.setlistItems.map(
        si => si.substr(0, 4) === "song" && fools.songfiledata.filter(sf => sf.songID === parseInt(si.substr(4)))
      )
      if (setlist.setlistItems.includes("SP")) files.splice(setlist.setlistItems.findIndex(i => i === "SP"), 1)
      dispatch(setCurrentSetlistFiles(files))
    }
  }

  if (loading) return <h1 className="text-4xl text-white font-hdr uppercase mt-10 text-center">Loading...</h1>
  return (
    <>
      {fools?.setlistdata.length < 1 ? (
        <h1 className="text-4xl text-white font-hdr uppercase mt-10 text-center">No Data !!</h1>
      ) : (
        <>
          <div className="flex justify-center align-bottom text-gray-200">
            {error && (
              <span className="flex mr-2">
                <FontAwesomeIcon icon="exclamation-triangle" className="text-yellow-400 mr-1" />{" "}
                An error occured: {error}
              </span>
            )}
            {saving && (
              <span className="flex mr-2">
                <img src={spinner} className="spinner width-10 height-10 mr-2" alt="loading" height={20} width={20} />
                Deleting...
              </span>
            )}
            {currentlyEditing && (<span className="text-xl text-green-600 my-2" onClick={(e) => { e.preventDefault(); history.push("/setlistedit/new") }}><FontAwesomeIcon icon="plus-square" className="mr-1" />Add a new Setlist</span>)}
          </div>
          <div>
            {fools.setlistdata.sort((a, b) => a.setlistDate > b.setlistDate ? -1 : 1).map((setlist, index) => {
              const listTime = setlist.setlistItems.reduce(
                (a, v) => a + (v.substr(0, 1) === "S" ? 0 : timeToSeconds(fools.songdata.find((s) => s.songID === parseInt(v.substr(4))).songTime)), 0)
              // console.log(setlist.setlistItems.filter(s => s !== "SP"))
              const tidiedSetlist = { ...setlist, setlistItems: setlist.setlistItems.filter((s, i) => { return s !== "SP" }) }
              return (
                <div className="setlistscreen flex flex-col w-300 mx-auto mt-2 text-gray-900" key={setlist.setlistID}>
                  <Link
                    to={`/songs/${setlist.setlistID}`}

                    onClick={() => { getFiles(setlist); dispatch(setCurrentSetlist(tidiedSetlist)) }}
                  >
                    <SetlistView
                      key={setlist.setlistID}
                      setlist={tidiedSetlist}
                      setlistItemCount={setlist.setlistItems.filter((i) => i.substr(0, 4) === "song").length}
                      setlistTime={listTime}
                    />
                  </Link>
                </div>
              )
            })}
          </div>
        </>
      )}

    </>
  )
}

export default SetlistScreen

import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Actions
import { setIsLoggedIn } from "../redux/actions/currentInfoActions"

import logo from '../images/coflogo.png'

const LoginScreen = () => {
  const VERSION = process.env.REACT_APP_VERSION

  const dispatch = useDispatch()
  const { loginExpiry, loginRole } = useSelector((state) => state.currentInfo)

  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [canClickDisabled, setCanClickDisabled] = useState(true)
  const [seePassword, setSeePassword] = useState(false)

  const passwordRef = useRef(password)

  const handleChange = (e) => {
    setPassword(e.target.value)
    passwordRef.current = e.target.value
    // console.log("passwordlength", e.target.value.length)
    if (e.target.value.length > 0) {
      setCanClickDisabled(false)
    }
    else {
      setCanClickDisabled(true)
    }
    // console.log("password", password)
  }

  const handleClick = () => {
    if (passwordRef.current.length === 0) return

    setCanClickDisabled(true)
    if (passwordRef.current === "t") {
      let e = new Date()
      dispatch(setIsLoggedIn({ isLoggedIn: true, loginExpiry: e.setHours(e.getHours() + 4), loginRole: "user" }))
      // dispatch(setIsLoggedIn({ isLoggedIn: true, loginExpiry: e.setMinutes(e.getMinutes() + 3) }))
    }
    else {
      if (passwordRef.current === "Tt") {
        let e = new Date()
        dispatch(setIsLoggedIn({ isLoggedIn: true, loginExpiry: e.setHours(e.getHours() + 4), loginRole: "admin" }))
        // dispatch(setIsLoggedIn({ isLoggedIn: true, loginExpiry: e.setMinutes(e.getMinutes() + 3) }))
      }
      else {
        setPassword("")
        setError("Uh-oh, wrong answer!!")
        setTimeout(() => { setPassword(""); setCanClickDisabled(true); setError("") }, 3000)
      }
    }
  }

  const togglePasswordVisibility = () => {
    setSeePassword(!seePassword)
  }

  useEffect(() => {
    //get last successful login time from localstorage
    const now = new Date().getTime()

    if (now > loginExpiry) {
      dispatch(setIsLoggedIn({ isLoggedIn: false, loginExpiry: null, loginRole: "user" }))
    }
    else {
      dispatch(setIsLoggedIn({ isLoggedIn: true, loginExpiry: loginExpiry, loginRole: loginRole }))

    }

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // console.log("Enter key was pressed.password is ", passwordRef.current);
        event.preventDefault()
        handleClick()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [dispatch, loginExpiry])

  return (
    <div className="homescreen flex items-center justify-center h-screen">

      <div className="homescreen-section logo flex flex-col flex-none items-center justify-center p-5">
        <img src={logo} width={200} alt="Chain of Fools" />
        <div className="relative w-full">
          <input className="text-gray-800 w-full rounded text-lg px-3 py-1 mt-2 mr-1" type={seePassword ? "text" : "password"} autoFocus id="password" name="password" value={password} onChange={(e) => handleChange(e)} placeholder="Top Secret Password" />
          <FontAwesomeIcon icon="eye" className="text-2xl text-gray-900 absolute bottom-2 right-1 mr-1" onClick={() => togglePasswordVisibility()} />
        </div>
        <button disabled={canClickDisabled} onClick={(e) => handleClick(e)} className={`${canClickDisabled ? "cursor-none" : "cursor-pointer"}`}>Log In</button>
        {error ? <p className="text-red-900 bg-gray-200 text-xl h-10 p-2">{error}</p> : <p className="text-xl h-10 p-2"></p>}
        <p className="text-xs w-full text-right">build {VERSION}</p>
      </div>
    </div>
  )
}

export default LoginScreen

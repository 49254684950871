import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function PrintFile() {

  return (
    <div className="flex content-center align-center m-auto w-min">
      <FontAwesomeIcon icon="cloud-download-alt" className="text-4xl text-yellow-400 bg-opacity-50" />
    </div >
  )

}

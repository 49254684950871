import * as actionTypes from "../constants/foolsConstants";

export const foolsReducer = (state = { fools: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      }
    case actionTypes.GET_DATA_SUCCESS:
      return {
        fools: action.payload,
        loading: false,
        error: "",
      }
    case actionTypes.GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case actionTypes.SET_SETLISTS_REQUEST:
    case actionTypes.SET_SONGS_REQUEST:
    case actionTypes.SET_SONGFILES_REQUEST:
      return {
        ...state,
        saving: true,
        error: "",
      }
    case actionTypes.SET_SETLISTS_SUCCESS:
    case actionTypes.SET_SONGS_SUCCESS:
    case actionTypes.SET_SONGFILES_SUCCESS:
      return {
        ...state,
        saving: false,
        error: "",
      }
    case actionTypes.SET_SETLISTS_FAIL:
    case actionTypes.SET_SONGS_FAIL:
    case actionTypes.SET_SONGFILES_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload,
      }
    case actionTypes.SET_UPLOAD_REQUEST:
      return {
        ...state,
        saving: true,
        newFile: '',
        error: "",
      }
    case actionTypes.SET_UPLOAD_SUCCESS:
      return {
        ...state,
        saving: false,
        newFile: action.payload,
        error: "",
      }
    case actionTypes.SET_UPLOAD_DONE:
      return {
        ...state,
        saving: false,
        newFile: "",
        error: "",
      }
    case actionTypes.SET_UPLOAD_FAIL:
      return {
        ...state,
        saving: false,
        newFile: '',
        error: action.payload
      }
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        saving: false,
        error: "",
      }
    default:
      return state
  }
};

import React, { useEffect, useState } from "react"
import { useHistory, Prompt } from "react-router-dom"
import ReactDOM from "react-dom"
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { addSongData, updateSongData, uploadSongFile, addSongFileData } from "../redux/actions/foolsActions"

import { EditText } from "react-edit-text"
import "react-edit-text/dist/index.css"

import { instruments } from './instruments'
import spinner from "../images/loading.svg"
import { CLEAR_ERROR } from "../redux/constants/foolsConstants"
import Metronome from './Metronome'

export default function SongEditor({ match }) {

  console.log("instruments", instruments)

  const dispatch = useDispatch()
  const history = useHistory()

  const { fools, saving, error, newFile } = useSelector((state) => state.fools)

  const [songName, setSongName] = useState("")
  const [songArtist, setSongArtist] = useState("")
  const [songTime, setSongTime] = useState("")
  const [songTempo, setSongTempo] = useState("")
  const [songKey, setSongKey] = useState("")
  const [songFiles, setSongFiles] = useState([])
  const [initialSong, setInitialSong] = useState({})
  const [initialSongID, setInitialSongID] = useState([])

  const [showFileSelector, setShowFileSelector] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [isSelected, setIsSelected] = useState(false)
  const [uploadMsg, setUploadMsg] = useState('')


  useEffect(() => {
    initialiseList()

  }, [match.params, fools.songdata, fools.songfiledata])

  useEffect(() => {
    if (newFile && newFile !== '') {
      const instruments = Array.from(document.querySelectorAll("#newfileinstruments > .instrumentactive")).map(e => e.dataset.instrument)
      const newID = parseInt(fools.songfiledata.reduce((m, v) => m = m > v.songfileID ? m : v.songfileID, 0)) + 1
      const newSongFile = {
        songfileID: newID,
        songfileName: '/' + newFile,
        songfileTypes: instruments,
        songID: initialSongID
      }
      const newFools = JSON.parse(JSON.stringify(fools))
      newFools.songfiledata[newFools.songfiledata.length] = newSongFile

      dispatch(addSongFileData(newSongFile, newFools))
    }
  }, [newFile, dispatch, fools, initialSongID])

  const initialiseList = () => {
    const songReq = match.params.songid
    // console.log('match', match.params)
    const origSong = {}
    if (songReq === "new") {
      const maxID = fools.songdata.reduce((m, v) => m = m > v.songID ? m : v.songID, 0)
      origSong.songID = parseInt(maxID) + 1
      origSong.songName = ""
      origSong.songArtist = ""
      origSong.songTime = ""
      origSong.songTempo = ""
      origSong.songKey = ""
      origSong.songFiles = []
    }
    else {
      const song = fools.songdata.find((s) => s.songID === parseInt(songReq))
      origSong.songID = song.songID
      origSong.songName = song.songName
      origSong.songArtist = song.songArtist
      origSong.songTime = song.songTime
      origSong.songTempo = song.songTempo
      origSong.songKey = song.songKey
      origSong.songFiles = [...fools.songfiledata.filter(f => f.songID === parseInt(songReq))]
    }
    setInitialSong(origSong)
    setInitialSongID(origSong.songID)
    setSongName(origSong.songName)
    setSongArtist(origSong.songArtist)
    setSongTime(origSong.songTime)
    setSongTempo(origSong.songTempo)
    setSongKey(origSong.songKey)
    const files = JSON.parse(JSON.stringify(origSong.songFiles))
    setSongFiles(files)
  }

  const changeInstruments = (ind, songfileTypes) => {
    const content = (<div className="i-selection modal-content flex flex-col w-300">
      <div className="flex flex-row gap-2">
        {instruments.map((i, ind) => {
          const bg = songfileTypes.includes(i.name) ? "instrumentactive" : ""
          return <div key={i.name} data-instrument={i.name} onClick={(e) => e.target.parentElement.classList.toggle("instrumentactive")} className={"w-16 bg-gray-500 " + bg}><img src={i.img} alt={i.name} /></div>
        })}
      </div>
      <div id="instrumentbuttons" className="text-2xl my-auto ml-3 py-8 text-gray-200 flex flex-row">
        <div className="cursor-pointer p-x-3 py-2 mr-2" onClick={() => saveInstrumentChanges(ind)}><FontAwesomeIcon icon="check-circle" className="text-green-600 mr-1" />OK</div>
        <div className="cursor-pointer p-x-3 py-2" onClick={() => closeInstrumentModal()}><FontAwesomeIcon icon="window-close" className="text-red-600 ml-5 mr-1" />Cancel</div>
      </div>
    </div>)

    const modal = document.getElementById('instmodal')
    ReactDOM.render(content, modal)
    const modalbg = document.getElementById('modalbg')
    modalbg.style.display = 'block'

  }
  const saveInstrumentChanges = (ind) => {
    const activeInstruments = document.getElementsByClassName("instrumentactive")
    if (activeInstruments.length !== 0) {
      const ins = []
      const chosenIcons = [...activeInstruments]
      chosenIcons.forEach((e) => ins.push(e.dataset.instrument))
      const newFiles = [...songFiles]
      newFiles[ind].songfileTypes = ins
      setSongFiles(newFiles)
      console.log('chg inst.newfiles', songFiles)
      closeInstrumentModal()
    }
    else {
      window.alert('You need to tag at least one instrument!')
    }
  }
  const closeInstrumentModal = () => {
    const modal = document.getElementById('instmodal')
    ReactDOM.render("", modal)
    document.getElementById('modalbg').style.display = 'none'
  }
  const deleteFile = (fileInd) => {
    console.log('deleting file', songFiles[fileInd])
    const newFiles = songFiles.filter((f, ind) => ind !== fileInd)
    setSongFiles(newFiles)
  }
  const saveSong = async () => {
    const newSong = {
      songID: initialSongID,
      songName: songName,
      songArtist: songArtist,
      songTime: songTime,
      songTempo: songTempo,
      songKey: songKey,
    }
    const oldSong = {
      songID: initialSongID,
      songName: initialSong.songName,
      songArtist: initialSong.songArtist,
      songTime: initialSong.songTime,
      songTempo: initialSong.songTempo,
      songKey: initialSong.songKey,
    }
    const newFiles = [...songFiles]
    const oldFiles = initialSong.songFiles

    const newSongData = JSON.stringify(newSong) !== JSON.stringify(oldSong)
    const newFileData = JSON.stringify(newFiles) !== JSON.stringify(oldFiles)
    // has songdata changed?
    if (newSongData || newFileData) {
      const newFools = JSON.parse(JSON.stringify(fools))
      if (newSongData) {
        const songInd = match.params.songid === "new" ? newFools.songdata.length : newFools.songdata.findIndex((s) => s.songID === initialSongID)
        newFools.songdata[songInd] = newSong
        newFools.songdata.sort((a, b) => (a.songName > b.songName ? 1 : -1))
        // console.log('songdata changing', newFools.songdata)
      }
      if (newFileData) {
        const newnewfiles = newFools.songfiledata.filter(f => f.songID !== initialSongID)
        // console.log('songfiledata filtered...', newnewfiles)
        newnewfiles.push(...newFiles)
        newnewfiles.sort((a, b) => (a.songfileID > b.songfileID ? 1 : -1))
        newFools.songfiledata = newnewfiles
        // console.log('songfiledata changing', newFools.songfiledata)

      }

      await Promise.all([
        match.params.songid === "new" ? dispatch(addSongData(newSong, newFools)) : dispatch(updateSongData(newSong, newFiles, newSongData, newFileData, newFools)),
        setInitialSong(newSong),
      ])

    }
    else {
      console.log('nothing changed!')
    }
    // history.push('/songs/all')
  }

  const checkIfChanges = () => {
    const newSong = {
      songID: initialSongID,
      songName: songName,
      songArtist: songArtist,
      songTime: songTime,
      songTempo: songTempo,
      songKey: songKey,
      songFiles: songFiles
    }
    // console.log(JSON.stringify(newSong), JSON.stringify(initialSong))
    // console.log('changed?', JSON.stringify(newSong) !== JSON.stringify(initialSong))
    return JSON.stringify(newSong) !== JSON.stringify(initialSong)
  }

  const changeUploadHandler = (event) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
  }

  const handleUploadSubmission = () => {
    const instruments = Array.from(document.querySelectorAll("#newfileinstruments > .instrumentactive")).map(e => e.dataset.instrument)
    if (instruments.length === 0) {
      setUploadMsg('Please select an instrument...')
      return false
    }
    setUploadMsg('')
    const formData = new FormData()
    formData.append('file', selectedFile)
    console.log('submitting upload form')
    dispatch(uploadSongFile(formData))
  }

  return (
    <div>
      <div className="flex justify-center text-gray-200">
        {error && (
          <span className="flex mr-2 my-auto">
            <FontAwesomeIcon icon="exclamation-triangle" className="text-yellow-400 mr-1" />{" "}
            An error occured: {error}
          </span>
        )}
        {saving && (
          <span className="flex mr-2 my-auto">
            <img src={spinner} className="spinner width-10 height-10 mr-2" alt="saving" height={20} width={20} />
            Saving...
          </span>
        )}
        <span onClick={() => { initialiseList(); dispatch({ type: CLEAR_ERROR }); history.push('/songs/all') }} className="mr-2 text-xl text-red-500 py-3 cursor-pointer">
          <FontAwesomeIcon icon="times-circle" className="fas fa-times-circle mr-1" />Discard |{" "}
        </span>
        <span onClick={() => saveSong()} className="text-xl text-green-600 py-3 cursor-pointer">
          <FontAwesomeIcon icon="save" className="mr-1" />Save Changes{" "}
        </span>
      </div>
      <div className="w-300 p-2 flex flex-col justify-center m-auto text-lg bg-gray-300">
        <div className="flex flex-col w-full">
          <div id="songlist" className="w-full">
            <p className="font-hdr text-xl">Song Data:</p>

            <div className="flex flex-row">
              <p className="editlabel">Song Name</p>
              <EditText className="editfield" name="songname" onChange={setSongName} value={songName} defaultValue={songName} placeholder="Song Name" />
            </div>
            <div className="flex flex-row">
              <p className="editlabel">Artist</p>
              <EditText className="editfield" name="songartist" onChange={setSongArtist} value={songArtist} defaultValue={songArtist} placeholder="Artist" />
            </div>
            <div className="flex flex-row">
              <p className="editlabel">Song Time</p>
              <EditText className="editfield" name="songtime" onChange={setSongTime} value={songTime} defaultValue={songTime} placeholder="00:00" />
            </div>
            <div className="flex flex-row">
              <p className="editlabel">Key</p>
              <EditText className="editfield" name="songkey" onChange={setSongKey} value={songKey} defaultValue={songKey} placeholder="Key" />
            </div>
            <div className="flex flex-row items-start content-start justify-start">
              <p className="editlabel">Tempo</p>
              <div className="flex flex-col w-full bg-gray-400 p-2">
                <EditText className="editfield" name="songtempo" onChange={setSongTempo} value={songTempo} defaultValue={songTempo} placeholder="120" />
                <div className="flex justify-center w-max">
                  <div className=" w-min flex flex-col mr-10">
                    <button className="text-3xl px-4 leading-none" onClick={() => songTempo < 240 && setSongTempo(parseInt(songTempo) + 5)}>+</button>
                    <button className="mt-1 text-3xl p-1 leading-none" onClick={() => songTempo > 60 && setSongTempo(parseInt(songTempo) - 5)}>-</button>
                  </div>
                  <Metronome tempo={songTempo} size={80} /></div>
              </div>
            </div>
          </div>
        </div>

        <div id="filelist" className="w-full mt-2">
          <p className="font-hdr text-xl">
            {songFiles.length > 0 ? "Song Files:" : "No Files for this song"}
            <span className="ml-4 text-green-600 text-base font-body cursor-pointer" onClick={() => setShowFileSelector(true)}>Upload new file <FontAwesomeIcon icon="upload" /></span>
          </p>
          {songFiles.length > 0 && (
            <>
              <div className="grid grid-cols-12 text-sm font-body text-gray-800">
                <span className="col-span-8">Filename</span>
                <span className="col-span-3 ml-2">Instruments</span>
                <span className="ml-2">Delete</span>
              </div>
              {songFiles.map((file, ind) => {
                const fileBaseName = file.songfileName?.split("/").pop()
                const fileType = file.songfileName?.split(".").pop().toLowerCase()
                let fileIconClass = ""
                switch (fileType) {
                  case 'pdf':
                    fileIconClass = "file-pdf"
                    break
                  case 'png':
                  case 'jpg':
                  case 'jpeg':
                    fileIconClass = "file-image"
                    break
                  case 'mp3':
                    fileIconClass = "file-audio"
                    break
                  default:
                    fileIconClass = "file-alt"
                }

                return (
                  <div key={file.songfileID} className="grid grid-cols-12">
                    <span className="col-span-8"><FontAwesomeIcon icon={fileIconClass} className=" mr-2 text-yellow-600 my-auto" />{fileBaseName}</span>
                    {/* <span className="col-span-3 flex ml-1" onClick={() => changeInstruments(ind, file.songfileTypes)}>{file.songfileTypes?.map((t, ind) => { const i = instruments.find(i => i.name === t).img; return (<img key={t + ind.toString()} src={i} alt={t} className="instrument-img" />) })}</span> */}
                    <span className="col-span-3 flex ml-1" onClick={() => changeInstruments(ind, file.songfileTypes)}>{file.songfileTypes?.map((t, ind) => { const i = instruments.find(i => i.name === t)?.icon({ color: "text-gray-800", size: "28" }); return (<div key={t + ind.toString()} className="instrument-img">{i}</div>) })}</span>
                    <FontAwesomeIcon icon="minus-circle" className="text-red-600 ml-4 my-auto" onClick={() => deleteFile(ind)} />
                  </div>
                )
              })}
            </>
          )}
          {showFileSelector && (<div className="">
            <div className="bg-gray-300 m-auto w-300 p-5">
              <input type="file" name="file" id="upload" onChange={changeUploadHandler} />
              {isSelected ? (
                <div id="newfileinstruments" className="flex flex-row gap-2 my-2">
                  {instruments.map((i, ind) =>
                    <div key={i.name} data-instrument={i.name} onClick={(e) => e.target.classList.toggle("instrumentactive")} className="p-0 rounded-sm instrument-img text-gray-800 bg-gray-500">{i.icon({ color: "text-gray-800", size: "28" })}</div>
                  )}
                </div>
              ) : (
                <p>Select a file to show details</p>
              )}
              {uploadMsg !== "" && <p className="text-red-400">{uploadMsg}</p>}
              <div>
                <button onClick={() => handleUploadSubmission()} className="mr-3 rounded-sm" >Submit</button>
                <button onClick={() => { setUploadMsg(""); setShowFileSelector(false) }} className="rounded-sm">Cancel</button>
              </div>
            </div>
          </div>
          )}

        </div>
        <Prompt when={checkIfChanges()} message="Did you want to abandon your changes?" />
        <div className="modal" id="modalbg" onClick={() => closeInstrumentModal()}>
          <div id="instmodal" onClick={(e) => e.stopPropagation()}></div>
        </div>
        {/* <CustomModal
          isBlocked={checkIfChanges()}
          title="Leave Page?"
          content="You have unsaved changes. Are you sure you want to leave this page?"
          extraFn={() => dispatch({ type: CLEAR_ERROR })}
        /> */}
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { setlistBreaks } from './setlistbreaks'
import { useDispatch, useSelector } from "react-redux"
import { setCurrentPath } from "../redux/actions/currentInfoActions"

import { timeToSeconds, secondsToTime } from "../util/util"

import Metronome from './Metronome'


const DocBreak = ({ match, touchS, touchM, touchE }) => {
  const dispatch = useDispatch()

  const { fools } = useSelector((state) => state.fools)
  const { currentSetlist, currentSetlistItem, currentPath } = useSelector((state) => state.currentInfo)

  const history = useHistory()

  const [timeAhead, setTimeAhead] = useState(0)
  const [countAhead, setCountAhead] = useState(0)

  const type = match.params.breaker
  useEffect(() => {
    dispatch(setCurrentPath("break"))

    let nextSection = currentSetlist.setlistItems.findIndex((i, ind) => ind > currentSetlistItem && i.substr(0, 1) === "S")
    if (nextSection === -1) nextSection = currentSetlist.setlistItems.length
    setTimeAhead(currentSetlist.setlistItems.slice(currentSetlistItem + 1, nextSection).reduce((a, v) => a + timeToSeconds(fools.songdata.find(s => s.songID === parseInt(v.substr(4))).songTime), 0))
    setCountAhead(currentSetlist.setlistItems.slice(currentSetlistItem + 1, nextSection).length)
  }, [fools.songdata, currentSetlist, currentSetlistItem])
  // console.log("timeAhead", timeAhead)
  // console.log("countAhead", countAhead)
  return (
    <div className="flex align-center justify-center"
      onTouchStart={touchS}
      onTouchMove={touchM}
      onTouchEnd={(e) => touchE(e, history)}>
      <div className="p-10 text-gray-100 text-2xl">
        <h1 className="text-4xl my-4">{setlistBreaks[type]} coming up...</h1>
        <p>Songs ahead... {countAhead}</p>
        <p>Time ahead... {secondsToTime(timeAhead)}</p>
        <p>For now, just relax...</p>
        <Metronome tempo="50" size={200} autoStart="true" />


      </div>
    </div >
  )
}

export default DocBreak
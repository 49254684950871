import * as actionTypes from "../constants/currentInfoConstants"

export const currentInfoReducer = (state = { currentInfo: [] }, action) => {
    switch (action.type) {
        case actionTypes.SET_NOT_FIRST_TIME_IN:
            return {
                ...state,
                firstTime: false,
            }
        case actionTypes.SET_FIRST_TIME_IN:
            return {
                ...state,
                firstTime: true,
            }
        case actionTypes.SET_CURRENT_INSTRUMENT_CHOICE:
            return {
                ...state,
                currentInstrument: action.payload,
            }
        case actionTypes.SET_COUNTER:
            return {
                ...state,
                counter: action.payload,
            }
        case actionTypes.INCREMENT_COUNTER:
            return {
                ...state,
                counter: state.counter + 1,
            }
        case actionTypes.SET_CURRENT_SETLIST:
            return {
                ...state,
                currentSetlist: action.payload,
            }
        case actionTypes.SET_CURRENT_SETLIST_FILES:
            return {
                ...state,
                currentSetlistFiles: action.payload,
            }
        case actionTypes.SET_CURRENT_SETLIST_ITEM:
            return {
                ...state,
                currentSetlistItem: action.payload,
            }
        case actionTypes.SET_CURRENT_EDIT_MODE:
            return {
                ...state,
                currentlyEditing: action.payload,
            }
        case actionTypes.SET_CURRENT_PATH:
            return {
                ...state,
                currentPath: action.payload
            }
        case actionTypes.SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                loginExpiry: action.payload.loginExpiry,
                loginRole: action.payload.loginRole,
            }
        case actionTypes.SET_SHOW_AUDIO:
            return {
                ...state,
                showAudio: action.payload
            }
        case actionTypes.SET_SCRIBBLE_CANVAS:
            return {
                ...state,
                scribbleCanvas: action.payload
            }
        case actionTypes.SET_PDF_ROTATE:
            return {
                ...state,
                pdfRotate: action.payload
            }


        default:
            return state
    }
}

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setCurrentPath, setCurrentSetlistItem } from "../redux/actions/currentInfoActions"

import { Link, useHistory } from 'react-router-dom'

import ReactAudioPlayer from 'react-audio-player'

import { instruments, transpose } from './instruments'

import Metronome from './Metronome'

const DocMissing = ({ match, touchS, touchM, touchE }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { fools } = useSelector((state) => state.fools)
  const { currentInstrument, currentPath, currentSetlist, currentSetlistItem, currentSetlistFiles, showAudio } = useSelector((state) => state.currentInfo)

  const currentTranspose = instruments.find(i => i.name === currentInstrument).transpose

  const [song, setSong] = useState({})
  const [songFilesList, setSongFilesList] = useState([])
  const [audioCacheFile, setAudioCacheFile] = useState('')
  const [audioFile, setAudioFile] = useState(null)

  const fileID = match.params.fileid

  const getAudioFromCache = async (file) => {
    const cache = await caches.open('foolscache')
    const inCache = await cache.match(file)
    if (inCache) {
      const c = await cache.match(file)
      const c2 = await c.arrayBuffer()
      await setAudioCacheFile(URL.createObjectURL(new Blob([c2])))
    }
    else {
      cache.add(file).then(async () => {
        const c = await cache.match(file)
        const c2 = await c.arrayBuffer()
        await setAudioCacheFile(URL.createObjectURL(new Blob([c2])))
      })
    }
  }

  useEffect(() => {
    dispatch(setCurrentPath("missing"))
    const thisSong = fools.songdata.find(s => s.songID === parseInt(fileID.substr(4)))
    const aFile = fools.songfiledata.find(f => f.songID == thisSong.songID && f.songfileTypes.includes("Audio Recording"))

    if (aFile) {
      setAudioFile(aFile.songfileName)
      getAudioFromCache(aFile.songfileName)
    }


    setSong(fools.songdata.find(s => s.songID == parseInt(fileID.substr(4))))
    setSongFilesList(fools.songfiledata.filter(f => f.songID == fileID.substr(4)))

  }, [fileID, fools.songdata])

  const [renderAudio, setRenderAudio] = useState(false)
  useEffect(() => {
    if (showAudio) {
      setTimeout(() => {
        setRenderAudio(true)
      }, 200)
    } else {
      setRenderAudio(false)
    }
  }, [showAudio])

  console.log("missing song", song, currentInstrument)
  return (
    <div className="flex flex-col align-center justify-center"
      onTouchStart={touchS}
      onTouchMove={touchM}
      onTouchEnd={(e) => touchE(e, history)}>
      {audioFile && showAudio && (<div className={`audio-holder ${renderAudio && 'show-audio'}`}>
        <ReactAudioPlayer
          src={audioFile}
          controls
        />
      </div>)}

      <div className="p-10 text-gray-100 text-2xl text-center">
        <h1 className="text-3xl my-4">I have no files for {currentInstrument} for</h1>
        <h1 className="text-5xl my-4">{song.songName}</h1>
        <p>Have a rest, or Make something up...</p>
        <p>...in the key of {song.songKey}  <span className="transpose ml-1">{currentTranspose !== 0 ? transpose(song.songKey, currentTranspose) : ''}</span></p>
        <p> at {song.songTempo}bpm</p>
        {currentInstrument === "Drums" ? (
          <Metronome tempo={song.songTempo?.toString()} size={300} autoStart="true" />
        ) : (
          <Metronome tempo={song.songTempo?.toString()} size={100} />
        )}
        <p>Or browse another instrument for this song...</p>
        <div className="song_files flex justify-center">
          {songFilesList.map((f, index) => {
            const fileType = f.songfileName?.split('.').pop() === 'pdf' ? '/pdf/' : '/audio/'
            return (
              <Link key={f.songfileID} to={`${fileType}${encodeURIComponent(f.songfileName)}`} onClick={(e) => { if (currentSetlistItem) dispatch(setCurrentSetlistItem(currentSetlistItem)) }}>
                <span className="fileblock flex ml-1" link={f.songfileName}>{f.songfileTypes?.map((t, ind) => { const k = t + ind; return (<div key={k} alt={t} className="instrument-img p-0 m-0 text-xs" >{instruments.find(i => i.name === t).icon({ color: "text-gray-800", size: "27" })}</div>) })}</span>
              </Link>
            )
          })}
        </div>
      </div>
    </div >
  )
}

export default DocMissing

export default function MyModal({ clicker, children }) {
  return (
    <>
      <div className=" max-w-6x flex flex-col overflow-y-auto">
        {/* <div className="border-0 rounded-lg shadow-lg relativew-full bg-white outline-none focus:outline-none p-10"> */}
        <div className="justify-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={clicker}>
          <div className="relative w-auto my-6 mx-auto max-w-6x flex flex-col" onClick={(e) => e.stopPropagation()}>
            <div className="mymodal border-0 rounded-lg shadow-lg relative w-full bg-gray-200 outline-none focus:outline-none p-10">
              {children}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

import * as actionTypes from "../constants/currentInfoConstants"

export const setCurrentPath = (newValue) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CURRENT_PATH,
        payload: newValue
    })
}

export const setNotFirstTime = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_NOT_FIRST_TIME_IN,
        payload: false,
    })
    localStorage.setItem("foolsInfo", JSON.stringify(getState().currentInfo))
}

export const setFirstTime = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_FIRST_TIME_IN,
        payload: true,
    })
    localStorage.setItem("foolsInfo", JSON.stringify(getState().currentInfo))

}

export const setCurrentInstrument = (instrument) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CURRENT_INSTRUMENT_CHOICE,
        payload: instrument,
    })
    localStorage.setItem("foolsInfo", JSON.stringify(getState().currentInfo))

}

export const setCounter = (value) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_COUNTER,
        payload: value,
    })
}

export const incrementCounter = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.INCREMENT_COUNTER,
    })
}

export const setCurrentSetlist = (value) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CURRENT_SETLIST,
        payload: value,
    })
}

export const setCurrentSetlistFiles = (value) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CURRENT_SETLIST_FILES,
        payload: value,
    })

}

export const setCurrentSetlistItem = (value) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CURRENT_SETLIST_ITEM,
        payload: value,
    })
}

export const setEditMode = (newValue) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CURRENT_EDIT_MODE,
        payload: newValue
    })
}

export const setIsLoggedIn = (newValue) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_IS_LOGGED_IN,
        payload: newValue
    })
    localStorage.setItem("foolsInfo", JSON.stringify(getState().currentInfo))
}

export const setShowAudio = (newValue) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_SHOW_AUDIO,
        payload: newValue
    })
}

export const setScribbleCanvas = (newValue) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_SCRIBBLE_CANVAS,
        payload: newValue
    })
}

export const setPDFRotate = (newValue) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_PDF_ROTATE,
        payload: newValue
    })
}
export const SET_FIRST_TIME_IN = "SET_FIRST_TIME_IN"
export const SET_NOT_FIRST_TIME_IN = "SET_NOT_FIRST_TIME_IN"
export const SET_CURRENT_INSTRUMENT_CHOICE = "SET_CURRENT_INSTRUMENT_CHOICE"
export const SET_COUNTER = "SET_COUNTER"
export const INCREMENT_COUNTER = "INCREMENT_COUNTER"
export const SET_CURRENT_SETLIST = "SET_CURRENT_SETLIST"
export const SET_CURRENT_SETLIST_FILES = "SET_CURRENT_SETLIST_FILES"
export const SET_CURRENT_SETLIST_ITEM = "SET_CURRENT_SETLIST_ITEM"
export const SET_CURRENT_EDIT_MODE = "SET_CURRENT_EDIT_MODE"
export const SET_CURRENT_PATH = "SET_CURRENT_PATH"
export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN"
export const SET_SHOW_AUDIO = "SET_SHOW_AUDIO"
export const SET_SCRIBBLE_CANVAS = "SET_SCRIBBLE_CANVAS"
export const SET_PDF_ROTATE = "SET_PDF_ROTATE"

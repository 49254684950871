import {datex} from '../util/util'
import mapImg from '../images/MapMarker.png'

export default function Gig({ gig }) {

  // const gigUrl = "/images/" + gig.gigImage.split("/").pop()
  return (
    <div className="gig flex gap-2">
      {/* <p><img src={gigUrl} alt={gig.gigTitle} className="border-2 border-gray-800 rounded w-48 mr-2" /></p> */}
      <div className="flex-row border-b pb-1 border-yellow-500 mb-1">
      <h3 className="text-hdr text-xl">{datex(gig.gigDate)}</h3>
      <p className="text-body text-base">{gig.gigTitle.replace("\\","")}</p>
      <a href={`https://www.google.com/maps/search/?api=1&query=${gig.venueCoordinates}`} target="_blank" rel="noreferrer"><p className="text-yellow-400 text-body text-base">@{gig.gigVenue}</p>
      <img src={mapImg} alt="Link to Map of Venue" className="w-14 ml-2" /></a>
      </div>
    </div>
  )
}

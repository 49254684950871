import * as actionTypes from "../constants/foolsConstants"
import axios from "axios"

export const getData = () => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.GET_DATA_REQUEST })

    const { data } = await axios.get("/api/getallsongdata")

    dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: data })
    localStorage.setItem("fools", JSON.stringify(getState().fools))
  } catch (error) {
    console.log('dispatch-error', error)
    dispatch({
      type: actionTypes.GET_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response.data,
    })
  }
}

export const updateSetlistData = (setlist, newdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.SET_SETLISTS_REQUEST })

    await axios.post("/api/setlist/" + setlist.setlistID, { setlist })

    dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
    localStorage.setItem("fools", JSON.stringify(getState().fools))

    return dispatch({ type: actionTypes.SET_SETLISTS_SUCCESS })
  } catch (error) {
    // console.log('dispatch-error',error.response)
    return dispatch({
      type: actionTypes.SET_SETLISTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response.data,
    })
  }
}
export const addSetlistData = (setlist, newdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.SET_SETLISTS_REQUEST })

    await axios.put("/api/setlist/", { setlist })

    dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
    localStorage.setItem("fools", JSON.stringify(getState().fools))

    return dispatch({ type: actionTypes.SET_SETLISTS_SUCCESS })
  } catch (error) {
    // console.log('dispatch-error',error.response)
    return dispatch({
      type: actionTypes.SET_SETLISTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response.data,
    })
  }
}
export const deleteSetlistData = (setlistID, newdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.SET_SETLISTS_REQUEST })

    await axios.delete("/api/setlist/" + setlistID)

    dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
    localStorage.setItem("fools", JSON.stringify(getState().fools))

    return dispatch({ type: actionTypes.SET_SETLISTS_SUCCESS })
  } catch (error) {
    // console.log('dispatch-error',error.response)
    return dispatch({
      type: actionTypes.SET_SETLISTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response.data,
    })
  }
}
export const updateSongData = (song, files, songchanged, fileschanged, newdata) => async (dispatch, getState) => {
  if (songchanged) {
    try {
      console.log('dispatcher:songchanged', song)
      dispatch({ type: actionTypes.SET_SONGS_REQUEST })

      await axios.post("/api/song/" + song.songID, { song })

      dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
      localStorage.setItem("fools", JSON.stringify(getState().fools))

      return dispatch({ type: actionTypes.SET_SONGS_SUCCESS })
    } catch (error) {
      // console.log('dispatch-error',error.response)
      return dispatch({
        type: actionTypes.SET_SONGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.response.data,
      })
    }
  }
  if (fileschanged) {
    console.log('dispatcher:fileschanged', files)
    try {
      dispatch({ type: actionTypes.SET_SONGFILES_REQUEST })

      await axios.post("/api/songfile/" + song.songID, { files })

      dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
      localStorage.setItem("fools", JSON.stringify(getState().fools))

      return dispatch({ type: actionTypes.SET_SONGFILES_SUCCESS })
    } catch (error) {
      // console.log('dispatch-error',error.response)
      return dispatch({
        type: actionTypes.SET_SONGFILES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.response.data,
      })
    }
  }
}
export const addSongData = (song, newdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.SET_SONGS_REQUEST })

    await axios.put("/api/song/", { song })

    dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
    localStorage.setItem("fools", JSON.stringify(getState().fools))

    return dispatch({ type: actionTypes.SET_SONGS_SUCCESS })
  } catch (error) {
    // console.log('dispatch-error',error.response)
    return dispatch({
      type: actionTypes.SET_SONGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response.data,
    })
  }
}
export const deleteSongData = (songID, newdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.SET_SONGS_REQUEST })

    await axios.delete("/api/song/" + songID)

    dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
    localStorage.setItem("fools", JSON.stringify(getState().fools))

    return dispatch({ type: actionTypes.SET_SONGS_SUCCESS })
  } catch (error) {
    // console.log('dispatch-error',error.response)
    return dispatch({
      type: actionTypes.SET_SONGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response.data,
    })
  }
}
export const uploadSongFile = (fileData) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.SET_UPLOAD_REQUEST })

    console.log('fileData', fileData)

    const res = await axios.post("/api/upload/", fileData)

    // dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
    // localStorage.setItem("fools", JSON.stringify(getState().fools))
    console.log('res', res.data.path)

    return dispatch({ type: actionTypes.SET_UPLOAD_SUCCESS, payload: res.data.path })
  } catch (error) {
    console.log('upload-dispatch-error', error.response)
    return dispatch({
      type: actionTypes.SET_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response.data,
    })
  }
}
export const addSongFileData = (songfile, newdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.SET_SONGFILES_REQUEST })

    await axios.put("/api/songfile/", { songfile })
    console.log('adding songfiledata finished put', newdata)
    dispatch({ type: actionTypes.GET_DATA_SUCCESS, payload: newdata })
    localStorage.setItem("fools", JSON.stringify(getState().fools))
    // dispatch({ type: actionTypes.SET_UPLOAD_DONE, payload: newdata })

    return dispatch({ type: actionTypes.SET_SONGFILES_SUCCESS })
  } catch (error) {
    // console.log('dispatch-error',error.response)
    return dispatch({
      type: actionTypes.SET_SONGFILES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response?.data,
    })
  }
}

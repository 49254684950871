import { useState } from "react"
import useAsyncEffect from 'use-async-effect'

import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import { setCounter, incrementCounter } from "../redux/actions/currentInfoActions"
import { sleep } from '../util/util'

export default function DownloadFilesScreen({ match }) {
  const dispatch = useDispatch()
  const { currentInstrument, counter, currentSetlist } = useSelector((state) => state.currentInfo)
  const { fools } = useSelector((state) => state.fools)

  const history = useHistory()

  const setlistID = match.params.setlistid
  const [runningOrder, setRunningOrder] = useState(0)
  const [fileCount, setFileCount] = useState(0)
  const [gettingFile, setGettingFile] = useState("")

  const [fileList, setFileList] = useState([])
  const [filesNotInCache, setFilesNotInCache] = useState([])

  const getCacheFiles = async () => {
    const cache = await caches.open('foolscache')
    const keys = cache ? await cache.keys() : []
    return (keys)
  }

  useAsyncEffect(async () => {
    const myCacheFiles = await getCacheFiles()
    const files = fools.setlistdata
      .find((s) => s.setlistID === parseInt(setlistID))
      .setlistItems.map(
        (si) =>
          si.substr(0, 4) === "song" &&
          fools.songfiledata
            .filter((so) => so.songID === parseInt(si.substr(4)) && so.songfileTypes.includes(currentInstrument))
      )

    const initialFileList = []
    files.forEach((item) => {
      if (item && item.length > 0) {
        initialFileList.push(...item)
      }
    })
    setFileList(initialFileList)
    setFileCount(initialFileList.length)
    dispatch(setCounter(0))

    const inCacheFiles = new Set(myCacheFiles.map(u => u.url.split("/").slice(3).join("/").replace(/^/, '/')))
    setFilesNotInCache([...new Set(initialFileList.filter(a => !inCacheFiles.has(a.songfileName)))])
  }, [currentInstrument])

  const downloadFiles = async (alwaysDownload) => {
    const cache = await caches.open('foolscache')

    var filesToGet = fileList

    if (!alwaysDownload) {
      filesToGet = filesNotInCache
      setFileCount(filesNotInCache.length)
    }

    if (currentInstrument) {

      dispatch(setCounter(0))

      for (const file of filesToGet) {
        setGettingFile(file.songfileName)

        await cache.add(file.songfileName)
        console.log("getting file ", file.songfileName)
        dispatch(incrementCounter())
        await sleep(70)
      }
      setGettingFile("")
      setRunningOrder(2)
    }
  }

  const needDownloads = filesNotInCache.length !== 0
  const dontNeedDownloads = filesNotInCache.length === fileList.length

  const missingFiles = needDownloads ? `(you're missing ${filesNotInCache.length} of the ${fileList.length})` : "(You have all of them already downloaded)"
  const downloadAllButton = !dontNeedDownloads ? (<button onClick={() => { setRunningOrder(1); downloadFiles(true) }}>Yes please, grab all of them in case they've changed</button>) : ((<button onClick={() => { setRunningOrder(1); downloadFiles(true) }}>Yes please</button>))
  const downloadSelectedButton = needDownloads && !dontNeedDownloads ? (<button onClick={() => { setRunningOrder(1); downloadFiles(false) }}>Yes please, skip existing</button>) : (<></>)
  return (
    <div className="homescreen flex items-center justify-center h-screen">
      <div className="homescreen-section logo flex flex-col items-center justify-center ">
        {runningOrder === 0 && (
          <>
            <p>Are you sure you want to download all  the {currentInstrument} files for the "{currentSetlist.setlistName}" setlist? <br /><span className="text-yellow-400 text-lg">{missingFiles}</span></p>
            <p>{downloadAllButton}</p>
            <p>{downloadSelectedButton}</p>
            <p><button className="secondary" onClick={() => { history.goBack() }}>No thank you</button></p>
          </>
        )}
        {runningOrder > 0 && (
          <><progress id="file-progress" value={counter} max={fileCount} /><p>{counter}/{fileCount}</p></>
        )}
        {runningOrder === 1 && (<p>Getting {gettingFile.split("/").pop()}</p>)}
        {runningOrder > 1 && (
          <p>All Done. <button onClick={() => { dispatch(setCounter(0)); history.goBack() }}>Take me back...</button></p>
        )}
      </div >
    </div >
  )
}


import { useEffect, useRef, useState } from 'react'
import './Metronome.css'

import { MetronomeIcon } from '../icons/MetronomeIcon.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Metronome({ tempo, size = "100", autoStart = "false" }) {

  const [animation, setAnimation] = useState(false)
  const pulserL = useRef(0)
  const pulserR = useRef(0)

  useEffect(() => {
    if (animation) {
      const duration = (60 / parseInt(tempo))
      pulserL.current.style.animation = `pulsate ${(duration * 2).toString()}s ease-in-out infinite both`
      pulserL.current.style.height = `${parseInt(size) / 2}px`
      pulserL.current.style.width = `${parseInt(size) / 2}px`
      pulserR.current.style.animation = `pulsate ${(duration * 2).toString()}s ease-in-out infinite both ${duration.toString()}s`
      pulserR.current.style.height = `${parseInt(size) / 2}px`
      pulserR.current.style.width = `${parseInt(size) / 2}px`
    }
  }, [tempo, size, animation])

  useEffect(() => {
    if (autoStart === "true") {
      setAnimation(true)
    }
  }, [autoStart])

  return (
    <div className="flex content-center align-center m-auto w-min">
      {animation ? (
        <div onClick={() => setAnimation(false)} style={{ marginLeft: size / 5, width: size }}>
          <div ref={pulserL} className="pulser rounded-full bg-yellow-400" ></div>
          <div ref={pulserR} className="pulser rounded-full bg-yellow-400" ></div>
        </div>
      ) : (
        <div className="metronome-shape  flex flex-col" onClick={() => setAnimation(true)}>
          <MetronomeIcon color={"text-yellow-400"} size={size} />
          <span className="ml-4 text-left text-yellow-300 animate-pulse text-lg">{tempo}<FontAwesomeIcon icon="hand-pointer" className="text-lg ml-1" /></span>
        </div>
      )
      }
    </div >
  )
}

let sl_array=[]
sl_array["S1"]="First Set"
sl_array["S2"]="Second Set"
sl_array["S3"]="Third Set"
sl_array["S4"]="Fourth Set"
sl_array["SE"]="Encore"
sl_array["SP"]="New Page"
sl_array["ST"]="Title"


export const setlistBreaks = sl_array
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Prompt, useHistory } from "react-router"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { EditText } from "react-edit-text"
import "react-edit-text/dist/index.css"

import spinner from "../images/loading.svg"
import { addSetlistData, updateSetlistData } from "../redux/actions/foolsActions"
import { CLEAR_ERROR } from "../redux/constants/foolsConstants"
import { secondsToTime, timeToSeconds } from "../util/util"

export default function SetlistEditor({ match }) {
  const history = useHistory()

  const dispatch = useDispatch()

  const { fools, saving, error } = useSelector((state) => state.fools)
  // const { currentSetlist } = useSelector((state) => state.currentInfo)

  const [setlistName, setSetlistName] = useState("")
  const [setlistSongsList, setSetlistSongs] = useState([])
  const [remainingSongsList, setRemainingSongs] = useState([])
  const [initialSetlist, setInitialSetlist] = useState({})
  const [initialSetlistID, setInitialSetlistID] = useState([])
  const [initialSetlistDate, setInitialSetlistDate] = useState([])
  const [setlistTime, setSetlistTime] = useState(0)

  useEffect(() => {
    initialiseList()
    // const unblock = history.block((location, action) => {
    //   if (checkIfChanges) {
    //     return window.confirm("Navigate Back?")
    //   }
    //   return true
    // });

    // return () => {
    //   unblock()
    // }
  }, [])

  const initialiseList = () => {
    const songsList = [
      { songID: "SE", songName: " Encore", songTime: "00:00" },
      { songID: "S1", songName: " First Set", songTime: "00:00" },
      { songID: "S2", songName: " Second Set", songTime: "00:00" },
      { songID: "S3", songName: " Third Set", songTime: "00:00" },
      { songID: "SP", songName: " New Page", songTime: "00:00" },
    ]
    songsList.push(...fools.songdata)
    // setRemainingSongs(songsList)
    // add in setlist songs to right side...
    const setlistReq = match.params.setlistid
    const origSetlist = {}
    if (setlistReq === "new") {
      const maxID = fools.setlistdata.reduce((m, v) => m = m > v.setlistID ? m : v.setlistID, 0)
      origSetlist.setlistID = parseInt(maxID) + 1
      origSetlist.setlistName = "New Setlist"
      origSetlist.setlistDate = new Date().toISOString().substr(0, 16).replace("T", " ")
      origSetlist.setlistItems = []
    }
    else {
      const sl = fools.setlistdata.find((s) => s.setlistID === parseInt(setlistReq))
      origSetlist.setlistID = sl.setlistID
      origSetlist.setlistName = sl.setlistName
      origSetlist.setlistDate = sl.setlistDate
      origSetlist.setlistItems = sl.setlistItems
    }
    setInitialSetlist(origSetlist)
    setInitialSetlistID(origSetlist.setlistID)
    setInitialSetlistDate(origSetlist.setlistDate)
    const setlistItems = origSetlist?.setlistItems.map((i) =>
      i[0] === "S" ? i : parseInt(i.substr(4))
    )
    const listName = origSetlist?.setlistName
    setSetlistName(listName)

    // console.log('setlistItems', setlistItems)
    const newSetlistList = []
    setlistItems.map((i) =>
      newSetlistList.push(songsList?.find((s) => s.songID === i))
    )

    const newRemainingList = songsList.filter(
      (s) => !setlistItems.includes(s.songID)
    )
    const newNewList = calcSetlistTime(newSetlistList)

    setRemainingSongs(newRemainingList)
    setSetlistSongs(newNewList)
  }
  const calcSetlistTime = (songList) => {
    // console.log('songList', songList)
    const listTime = [0, 0, 0, 0, 0]
    listTime[0] = songList.reduce(
      (a, v) =>
        a + (v.songID.toString()[0] !== "S" ? timeToSeconds(v.songTime) : 0),
      0
    )
    setSetlistTime(listTime[0])

    let totCounter = 0
    songList.forEach((s) => {
      if (s.songID.toString()[0] === "S") {
        totCounter++
      } else {
        listTime[totCounter] += timeToSeconds(s.songTime)
      }
    })
    const newList = new Array(...songList)
    totCounter = 0
    newList.forEach((s) => {
      if (s.songID.toString()[0] === "S") {
        s.songTime = secondsToTime(listTime[++totCounter])
      }
    })
    setSetlistSongs(newList)
    // console.log('listTime', listTime)
    return newList
  }

  const addSongToList = (song, ind) => {
    const newList = new Array(...setlistSongsList)
    newList.push(song)
    const newNewList = calcSetlistTime(newList)
    setSetlistSongs(newNewList)
    const remainingSongsNow = remainingSongsList.filter(
      (s) => s.songID !== song.songID
    )
    setRemainingSongs(remainingSongsNow)
    // console.log('setlistSongsList', newNewList)
    // console.log('setlistTime', setlistTime)
  }
  const removeSongFromList = (song, ind) => {
    const newList = new Array(...remainingSongsList)
    newList.push(song)
    newList.sort((a, b) => (a.songName > b.songName && 1) || -1)
    setRemainingSongs(newList)
    const newSetlistList = new Array(
      ...setlistSongsList.filter((s) => s.songID !== song.songID)
    )
    const newNewList = calcSetlistTime(newSetlistList)
    setSetlistSongs(newNewList)
    // console.log('setlistSongsList', newNewList)
  }
  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(setlistSongsList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    const newItems = calcSetlistTime(items)
    setSetlistSongs(newItems)
  }

  function updateDate() {
    function pad(n) { return n < 10 ? "0" + n : n }

    const d = new Date()
    const dash = "-"
    const colon = ":"
    return d.getFullYear() + dash +
      pad(d.getMonth() + 1) + dash +
      pad(d.getDate()) + " " +
      pad(d.getHours()) + colon +
      pad(d.getMinutes()) + colon +
      pad(d.getSeconds())
  }


  const saveSetlist = async () => {
    const newSetlist = {
      setlistID: initialSetlistID,
      setlistName: setlistName,
      setlistDate: updateDate(),
      setlistItems: setlistSongsList.map((s) =>
        s.songID[0] === "S" ? s.songID : "song" + s.songID
      ),
    }
    // console.log(newSetlist)
    const newFools = JSON.parse(JSON.stringify(fools))
    const setlistInd = match.params.setlistid === "new" ? newFools.setlistdata.length : newFools.setlistdata.findIndex((s) => s.setlistID === initialSetlistID)
    newFools.setlistdata[setlistInd] = newSetlist
    // console.log('newFools', newFools)

    await Promise.all([
      match.params.setlistid === "new" ? dispatch(addSetlistData(newSetlist, newFools)) : dispatch(updateSetlistData(newSetlist, newFools)),
    ])
    setInitialSetlist(newSetlist)
  }

  function checkIfChanges() {
    const newSetlist = {
      setlistID: initialSetlistID,
      setlistName: setlistName,
      setlistDate: initialSetlistDate,
      setlistItems: setlistSongsList.map((s) =>
        s.songID[0] === "S" ? s.songID : "song" + s.songID
      ),
    }
    // console.log("before", JSON.stringify(initialSetlist))
    // console.log("after", JSON.stringify(newSetlist))
    return JSON.stringify(newSetlist) !== JSON.stringify(initialSetlist)
  }

  return (
    <div>
      <div className="flex justify-center text-gray-200">
        {error && (
          <span className="flex mr-2 my-auto">
            <FontAwesomeIcon icon="exclamation-triangle" className="ftext-yellow-400 mr-1" />{" "}
            An error occured: {error}
          </span>
        )}
        {saving && (
          <span className="flex mr-2 my-auto">
            <img src={spinner} className="spinner width-10 height-10 mr-2" alt="loading" height={20} width={20} />
            Saving...
          </span>
        )}
        <span onClick={() => { initialiseList(); dispatch({ type: CLEAR_ERROR }); history.goBack() }} className="mr-2 text-xl text-red-500 py-3 cursor-pointer">
          <FontAwesomeIcon icon="times-circle" className="mr-1" />Discard |{" "}
        </span>
        <span onClick={() => saveSetlist()} className="text-xl text-green-600 py-3 cursor-pointer">
          <FontAwesomeIcon icon="save" className="mr-1" />Save Changes{" "}
        </span>
      </div>
      <div className="w-300 p-2 flex flex-col justify-center m-auto text-lg">
        <div className="flex flex-row">
          <div
            id="songlist"
            className="w-1/3 h-screen overflow-scroll bg-gray-600 p-2 text-gray-100"
          >
            <h2 className="text-hdr text-2xl text-gray-900 pb-2 mb-2 border-yellow-400 border-b-2">
              Songs
            </h2>
            <ul>
              {remainingSongsList.map((s, ind) => {
                const breaker = s.songID.toString().substr(0, 1) === "S"
                const liClass = breaker
                  ? "bg-gray-800 text-gray-100 p-1 flex justify-between border-gray-600 border-b-2"
                  : "p-1 flex justify-between border-gray-800 border-b-2"
                return (
                  <li
                    key={s.songID}
                    className={liClass}
                    onClick={() => addSongToList(s, ind)}
                  >
                    {s.songName}{" "}
                    <FontAwesomeIcon icon="chevron-right" className="text-gray-300" />
                  </li>
                )
              })}
            </ul>
          </div>
          <div
            id="setlistlist"
            className="w-2/3 h-screen overflow-scroll bg-yellow-400 p-2"
          >
            <h2 className="text-hdr text-2xl pb-2 mb-2 border-gray-800 border-b-2 flex justify-between">
              <EditText
                name="setlistname"
                onChange={setSetlistName}
                value={setlistName}
                defaultValue={setlistName}
              />
              <span className="text-right text-sm">
                {secondsToTime(setlistTime)} time
              </span>
            </h2>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="songs">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {setlistSongsList.map((s, ind) => {
                      const breaker = s.songID.toString().substr(0, 1) === "S"
                      const liClass = breaker
                        ? "bg-gray-800 text-gray-100 p-2 flex justify-between"
                        : "flex justify-between"
                      return (
                        <Draggable
                          key={s.songID}
                          draggableId={s.songID.toString()}
                          index={ind}
                        >
                          {(provided) => (
                            <li
                              className={liClass}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <span>
                                <FontAwesomeIcon icon="chevron-left" className="text-gray-300" onClick={() => removeSongFromList(s, ind)} />{" "}
                                {s.songName}
                                {!breaker && ", " + s.songArtist}
                              </span>
                              <span>{s.songTime}</span>
                            </li>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <Prompt when={checkIfChanges()} message="Did you want to abandon your changes?" />
        {/* <CustomModal
          isBlocked={checkIfChanges()}
          title="Leave Page?"
          content="You have unsaved changes. Are you sure you want to leave this page?"
          extraFn={() => dispatch({ type: CLEAR_ERROR })}
        /> */}
        {/* <div id="modal"></div> */}
      </div>
    </div>
  )
}

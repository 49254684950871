import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import axios from 'axios'
import spinner from '../images/loading.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { secondsToTime } from "../util/util"
import { deleteSetlistData } from "../redux/actions/foolsActions"
import { setCurrentSetlist } from "../redux/actions/currentInfoActions"

const SetlistView = ({ setlist, setlistItemCount, setlistTime }) => {

  const { currentlyEditing } = useSelector((state) => state.currentInfo)
  const { fools } = useSelector((state) => state.fools)

  const history = useHistory()
  const dispatch = useDispatch()

  const deleteSetlist = async () => {
    //remove setlist from setlistdata array in fools
    //dispatch update to data
    if (window.confirm(`Are you sure you want to PERMANENTLY DELETE this setlist? `)) {
      const newFools = fools
      newFools.setlistdata = newFools.setlistdata.filter((s) => s.setlistID !== parseInt(setlist.setlistID))
      await dispatch(deleteSetlistData(setlist.setlistID, newFools))
    }
  }

  const [downloading, setDownloading] = useState(false)

  const downloadPDF = async (setlistID) => {
    setDownloading(true)
    const res = await axios({ url: "/api/pdfsetlist/" + setlistID.toString(), method: "post", responseType: "blob" })
    // console.log("pdf response", res.headers["content-disposition"].split("=")[1])
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', res.headers["content-disposition"].split("=")[1])
    document.body.appendChild(link)
    link.click()
    link.remove()
    setDownloading(false)
  }

  return (
    <div className="song-item w-300 flex  justify-between mx-auto">
      <div><p className="text-3xl font-hdr">
        {setlist.setlistName}
        {currentlyEditing && <FontAwesomeIcon icon="edit" className="text-lg ml-4 text-yellow-600" onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push('/setlistedit/' + setlist.setlistID) }} />}
        {currentlyEditing && <FontAwesomeIcon icon="minus-circle" className="text-lg ml-4 text-red-600" onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteSetlist() }} />}
      </p></div>
      <div className="flex flex-row">
        <div className="setlist__info text-right" >
          <p><span className="setlist__label">total of </span>{setlistItemCount}<span className="setlist__label"> songs</span></p>
          <p><span className="setlist__label">running time: </span>{secondsToTime(setlistTime)}</p>
        </div>
        <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); downloadPDF(setlist.setlistID) }} className="cursor-pointer"><FontAwesomeIcon icon="file-pdf" className="text-red-600 text-3xl ml-2" />{downloading && <img src={spinner} className="spinner width-10 height-10 mr-2" alt="loading" height={20} width={20} />}</div>
        <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); dispatch(setCurrentSetlist(setlist)); history.push("/downloader/" + setlist.setlistID) }} className="cursor-pointer"><FontAwesomeIcon icon="cloud-download-alt" className="text-3xl ml-2" /></div>
      </div>
    </div>
  );
};

export default SetlistView;
import { useEffect, useState } from "react"
import useAsyncEffect from 'use-async-effect'
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Actions
import { getData } from "../redux/actions/foolsActions"
import { setIsLoggedIn, setFirstTime, setNotFirstTime, setCurrentPath, setCounter, incrementCounter, setCurrentInstrument, setEditMode } from "../redux/actions/currentInfoActions"
import { Link } from "react-router-dom"

import logo from '../images/coflogo.png'
import spinner from '../images/loading.svg'
import { instruments } from '../components/instruments'
import { datex, sleep, cachesSize } from '../util/util'
import Gig from "../components/Gig"
import MyModal from '../components/modal/MyModal'

import { LyricsIcon } from '../icons/LyricsIcon.js'

const HomeScreen = () => {
  const VERSION = process.env.REACT_APP_VERSION
  const BYTES_PER_MB = 1024 ** 2
  const dispatch = useDispatch()

  const { fools, loading, error } = useSelector((state) => state.fools)
  const { loginExpiry, loginRole, firstTime, counter, currentInstrument } = useSelector((state) => state.currentInfo)

  const [initialFileCount, setInitialCount] = useState(-1)
  const [cacheSize, setCacheSize] = useState(0)
  const [cacheFiles, setCacheFiles] = useState([])
  const [showInstrumentSelector, setShowInstrumentSelector] = useState(false)
  const [showCacheModal, setShowCacheModal] = useState(false)
  const [showEmptyCacheModal, setShowEmptyCacheModal] = useState(false)
  const [okToContinue, setOkToContinue] = useState(false)

  const getCacheFiles = async () => {
    const cache = await caches.open('foolscache')
    const keys = cache ? await cache.keys() : []
    setCacheFiles(keys)
    return (keys.length)
  }
  useAsyncEffect(async () => {
    if (initialFileCount === -1) {
      setInitialCount(await getCacheFiles())
      setCacheSize(await cachesSize())
      dispatch(setCounter(0))
    }
  }, [])

  useEffect(() => {
    if (firstTime) {
      dispatch(getData())
      dispatch(setNotFirstTime())
    }
    dispatch(setCurrentPath("home"))

  }, [dispatch, firstTime])

  if (error) return (
    <div className="homescreen">
      <h2 className="homescreen-title">Stats</h2>
      <div className="homescreen__songs">
        <h2>Error...{error}</h2>
      </div>
    </div>
  )
  if (!fools) return (
    <div className="homescreen">
      <h2 className="homescreen-title">Stats</h2>
      <div className="homescreen__songs">
        <h2>no data yet...</h2>
      </div>
    </div>
  )
  const setFirstTimeHandler = () => {
    dispatch(setFirstTime())
    dispatch(getData())
    dispatch(setNotFirstTime())
  }


  const clearCache = async () => {
    dispatch(setCounter(0))
    const cache = await caches.open('foolscache')
    const keys = await cache.keys()

    for (const k of keys) {
      await cache.delete(k)
      dispatch(incrementCounter())
      await sleep(10)
    }
    setInitialCount(await getCacheFiles())
    setCacheSize(await cachesSize())

    await sleep(1000)
    setShowEmptyCacheModal(false)
  }

  const deleteOneFile = async (key, fileID) => {
    // console.log(key)
    const cache = await caches.open('foolscache')
    await cache.delete(key)
    setInitialCount(await getCacheFiles())
    setCacheSize(await cachesSize())

    const elem = document.getElementById(fileID)
    elem.parentNode.removeChild(elem)
  }

  const iClick = (i) => {
    dispatch(setCurrentInstrument(i))
    dispatch(setEditMode(false))
    setShowInstrumentSelector(false)
  }
  const imgURL = currentInstrument ? instruments.find(i => i.name === currentInstrument).img : ''
  const instrumentIcon = currentInstrument ? instruments.find(i => i.name === currentInstrument).icon : ''
  const expTime = loginExpiry ? new Date(loginExpiry).toTimeString().substr(0, 8) : "dunno"

  return (
    <div className="homescreen grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

      <div className="homescreen-section stats">
        <h2 className="homescreen-title"><FontAwesomeIcon icon={['fas', 'info-circle']} className="mr-2" />Stats</h2>
        {!fools?.lastrefresh ? (
          <p>No App Data found. Please refresh...</p>
        ) : (
          <p>Last data refresh: <br /> {datex(fools.lastrefresh)}</p>
        )}
        <p className="flex items-center">
          <button onClick={setFirstTimeHandler}>Refresh Data Now</button>{loading && <span className="flex ml-2"><img src={spinner} className="spinner width-10 height-10 mr-2" alt="loading" height={20} width={20} /> Loading...</span>}

        </p>
      </div>

      <Link to="/setlists">
        <div className="homescreen-section setlists">
          <h2 className="homescreen-title"><FontAwesomeIcon icon="list" className="mr-2" />Setlists</h2>
          <p>Setlists found: {fools.setlistdata.length}</p>
        </div>
      </Link>

      <Link to="/songs/all">
        <div className="homescreen-section songs">
          <h2 className="homescreen-title"><FontAwesomeIcon icon="music" className="mr-2" />Songs</h2>
          <p>Songs found: {fools.songdata.length}</p>
          <p>Song files found: {fools.songfiledata.length}</p>
        </div>
      </Link>

      <div className="homescreen-section cache">
        <h2 className="homescreen-title flex justify-between">
          <span><FontAwesomeIcon icon="cogs" className="mr-2" />Cache</span>
        </h2>
        {/* <div className="w-full flex flex-row items-center">
          <div className="mr-3 flex flex-col items-center">
            <div className="relative w-min bg-yellow-400 cursor-pointer p-0 rounded-md animate-pulse text-gray-800" onClick={() => setShowInstrumentSelector(true)}>
              {instrumentIcon({ size: "50" })}
            </div>
          </div>
        </div> */}
        <p><FontAwesomeIcon icon="file-medical-alt" className="text-2xl mr-2" />Currently {initialFileCount > 0 ? initialFileCount.toString() : "no"} files in the cache<br />
          <FontAwesomeIcon icon="glass-whiskey" className="text-2xl mr-2" /> Occupying {cacheSize < BYTES_PER_MB ? (cacheSize / 1024).toFixed(2) + "KB" : (cacheSize / BYTES_PER_MB).toFixed(2) + "MB"} space</p>
        {initialFileCount && initialFileCount > 0 ? <p><button id="settingsoptions" className="secondary" onClick={() => { setOkToContinue(false); setShowEmptyCacheModal(true) }}>Empty Cache</button><button id="showfiles" onClick={() => setShowCacheModal(true)}>Show Files</button></p> : ''}
        <p>App version: {VERSION} <button id="showfiles" onClick={() => window.location.reload(true)}>Check for Update</button></p>
      </div>

      <div className="homescreen-section gigs">
        <h2 className="homescreen-title"><a href="http://www.chainoffools.co.uk/gig-list" target="_blank" rel="noreferrer"><FontAwesomeIcon icon="calendar-check" className="mr-2" />Gigs</a></h2>
        <p className="text-xl font-bold">Upcoming Gigs found: <span className="text-yellow-400">{fools.gigdata.length}</span></p>
        <div className="flex-col gap-2">
          {fools.gigdata.length > 0 && (
            <>
              <p className="text-xl font-bold text-yellow-400">Next 2 gigs:</p>
              <Gig key={fools.gigdata[0].gigDate} gig={fools.gigdata[0]} />
              {fools.gigdata.length > 1 && <Gig key={fools.gigdata[1].gigDate} gig={fools.gigdata[1]} />}
              <p className="text-yellow-400"><a href="http://chainoffools.co.uk/gig-list" target="_blank" rel="noreferrer">Visit the Website Gig List</a></p>
            </>
          )}
        </div>
      </div>


      <div className="homescreen-section logo p-5">
        <p><img src={logo} width={200} alt="Chain of Fools" /></p>
        <div className="flex flex-col m-0">
          <span className="cursor-pointer text-xl m-0" onClick={() => dispatch(setIsLoggedIn({ isLoggedIn: false, loginExpiry: null, loginRole: "user" }))}>
            <FontAwesomeIcon icon="sign-out-alt" className="text-yellow-400 mr-2" />Sign Out
          </span>
        </div>
        <p className="text-sm m-0">Login expires {expTime}{loginRole === "admin" && <>  (admin login)</>}</p>
        <p className="text-xl mr-3">Choose your instrument</p>
        <p onClick={() => setShowInstrumentSelector(true)}>{instrumentIcon({ color: "text-yellow-400", size: "150" })}</p>
        <p className="text-xl m-1">{currentInstrument}</p>

      </div>

      {/* Modal windows Section */}

      {showInstrumentSelector && (
        <MyModal clicker={() => setShowInstrumentSelector(false)}>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            Choose your weapon
          </h3>
          <div className=" flex flex-col md:flex-row ">
            {instruments.map((i) => (
              <div key={i.name} className="flex flex-row md:flex-col items-center">
                <div className="instrumentselector h-12 w-12 flex-shrink" onClick={() => iClick(i.name)} src={i.img} alt={i.name}>{i.icon({ color: "text-gray-800", size: "50" })}</div>
                <span className=" text-sm text-gray-900 font-body">{i.name}</span>
              </div>
            ))}
          </div>
        </MyModal>
      )}

      {showEmptyCacheModal && (
        <MyModal clicker={() => setShowEmptyCacheModal(false)}>
          {!okToContinue ? (
            <>
              <p>Are you sure you want to delete all the files you have stored?</p>
              <p className="mauto-x"><button onClick={() => { setOkToContinue(true); clearCache() }}>Yes please</button><button onClick={() => setShowEmptyCacheModal(false)}>No thank you</button></p>
            </>
          ) : (
            <progress id="file-progress" value={counter} max={initialFileCount} />
          )}
        </MyModal>
      )}

      {showCacheModal && (
        <MyModal clicker={() => setShowCacheModal(false)}>
          <div id="filesmodal" onClick={(e) => e.stopPropagation()}>
            {cacheFiles.map((f, ind) => {
              const fname = '/' + f.url.split('/').slice(-2).join('/')
              const assocFile = fools.songfiledata.find(x => x.songfileName === fname)
              const assocFileTypes = assocFile?.songfileTypes || []
              const assocSong = fools.songdata.find(s => s.songID === assocFile?.songID)
              const fileID = 'file' + ind
              return (
                <div key={fname} className="cacheline flex flex-row justify-between text-gray-900 border-b-2 border-gray-300" id={fileID}>
                  <div className="cachefile flex flex-row justify-between w-full">
                    <p className="file">{assocSong?.songName} - {fname.split('/').pop()}</p>
                    <p className="flex flex-row ml-1">{assocFileTypes?.map((t, ind) => { const k = t + ind; return (<img key={k} src={instruments.find(i => i.name === t).img} alt={t} className="instrument-img" />) })}</p>
                  </div>
                  <div className="cacheactions">
                    <FontAwesomeIcon icon="trash-alt" className="ml-2" onClick={() => deleteOneFile(f, fileID)} />
                  </div>
                </div>
              )
            })}
          </div>
        </MyModal>
      )}
    </div >
  )
}

export default HomeScreen

import { useState } from "react"
import { HashRouter as Router, Switch, Route, useHistory } from "react-router-dom"
// import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { setCurrentSetlistItem } from "./redux/actions/currentInfoActions"

// Components
import Navbar from "./components/Navbar"

// Screens
import HomeScreen from "./screens/HomeScreen"
import SongScreen from "./screens/SongScreen"
import SetlistScreen from "./screens/SetlistScreen"
import DocPDF from "./components/DocPDF"
import DocImage from "./components/DocImage"
import DocAudio from "./components/DocAudio"
import DocBreak from "./components/DocBreak"
import DocMissing from "./components/DocMissing"
import SetlistEditor from "./components/SetlistEditor"
import SongEditor from "./components/SongEditor"
import LoginScreen from "./screens/LoginScreen"
import DownloadFilesScreen from './screens/DownloadFilesScreen'

import useWindowWidth from "./util/useWindowWidth"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faList, faCogs, faMusic, faCalendarCheck, faSignOutAlt, faEye, faAngleDoubleUp, faArrowAltCircleLeft, faArrowAltCircleRight, faInfoCircle, faEdit, faFilePdf, faCloudDownloadAlt, faExclamationTriangle, faTimesCircle, faSave, faChevronRight, faChevronLeft, faMinusCircle, faHistory, faTachometerAlt, faFileImage, faFileAlt, faFileAudio, faFileMedicalAlt, faTrashAlt, faPlusSquare, faUpload, faHandPointer, faPrint, faCheckCircle, faWindowClose, faGlassWhiskey, faVolumeUp, faPencilAlt, faHighlighter, faUndo, faEraser, faRetweet } from '@fortawesome/free-solid-svg-icons'
library.add(faList, faCogs, faMusic, faCalendarCheck, faSignOutAlt, faEye, faAngleDoubleUp, faArrowAltCircleLeft, faArrowAltCircleRight, faInfoCircle, faEdit, faFilePdf, faCloudDownloadAlt, faExclamationTriangle, faTimesCircle, faSave, faChevronRight, faChevronLeft, faMinusCircle, faHistory, faTachometerAlt, faFileImage, faFileAlt, faFileAudio, faFileMedicalAlt, faTrashAlt, faPlusSquare, faUpload, faHandPointer, faPrint, faCheckCircle, faWindowClose, faGlassWhiskey, faVolumeUp, faPencilAlt, faHighlighter, faUndo, faEraser, faRetweet)



require('typeface-anton')
require('typeface-antonio')

function App() {
  const dispatch = useDispatch()
  const { currentInstrument, currentSetlist, currentSetlistFiles, currentSetlistItem, isLoggedIn, loginExpiry, currentPath } = useSelector((state) => state.currentInfo)

  const [latestTouchData, setTouchData] = useState({})
  const [initialTouchData, setInitialTouchData] = useState({})

  const width = useWindowWidth()
  const now = new Date().getTime()

  const getNext = (direction, history) => {
    const delta = direction === "fwd" ? 1 : -1
    // console.log('history', history)
    if ((direction === "fwd" && currentSetlistItem !== null && currentSetlistItem < currentSetlist.setlistItems.length - 1) || (direction === "bwd" && currentSetlistItem > 0)) {
      let nextSongItem = currentSetlist.setlistItems[currentSetlistItem + delta]
      // if (nextSongItem === "SP") nextSongItem = currentSetlist.setlistItems[currentSetlistItem + (delta * 2)]
      // console.log('nextSongItem', nextSongItem)

      if (nextSongItem.substr(0, 1) === "S") {
        //break next
        history.push('/break/' + nextSongItem)
      }
      else {
        const nextSongFiles = currentSetlistFiles[currentSetlistItem + delta]
        // console.log('nextSongFiles', nextSongFiles)
        if (nextSongFiles.length > 0) {
          // file next
          const defaultFile = nextSongFiles.find(fi => fi.songfileTypes?.includes(currentInstrument))?.songfileName
          // console.log("defaultFile", defaultFile === undefined)
          if (defaultFile === undefined) {
            history.push('/nofile/' + nextSongItem)
          }
          else {
            const defaultExt = defaultFile ? defaultFile.split('.').pop() : ''
            const defaultPath = defaultExt === 'pdf' ? '/pdf/' : '/audio/'
            // console.log('defaultPath', defaultPath, 'defaultfile', defaultFile)
            history.push(defaultPath + encodeURIComponent(defaultFile))
          }
        }
        else {
          // no file found
          history.push('/nofile/' + nextSongItem)
        }
      }
      dispatch(setCurrentSetlistItem(currentSetlistItem + delta))
    }
  }

  const touchS = (e) => {
    setInitialTouchData({ timeStamp: e.timeStamp, x: e.touches[0].screenX, y: e.touches[0].screenY })
  }
  const touchM = (e) => {
    setTouchData({ timeStamp: e.timeStamp, x: e.touches[0].screenX, y: e.touches[0].screenY })
  }
  const touchE = (e, history) => {
    // console.log("moved ", latestTouchData.x - initialTouchData.x, "across, and ", latestTouchData.y - initialTouchData.y, "down, and took", latestTouchData.timeStamp - initialTouchData.timeStamp, "seconds")
    latestTouchData.x < initialTouchData.x && Math.abs(latestTouchData.x - initialTouchData.x) > (width * 0.25) && Math.abs(latestTouchData.y - initialTouchData.y) < 80 && latestTouchData.timeStamp - initialTouchData.timeStamp < 1000 && getNext('fwd', history)
    latestTouchData.x > initialTouchData.x && Math.abs(latestTouchData.x - initialTouchData.x) > (width * 0.25) && Math.abs(latestTouchData.y - initialTouchData.y) < 80 && latestTouchData.timeStamp - initialTouchData.timeStamp < 1000 && getNext('bwd', history)
  }


  if ((loginExpiry || now > loginExpiry) && !isLoggedIn) {
    // history.push('/')
    return <LoginScreen />
  }
  return (
    <Router>
      {currentPath !== "home" && <Navbar getNext={getNext} />}
      <main className="app bg-gray-700">
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/songs/:setlistid" component={SongScreen} />
          <Route exact path="/setlists" component={SetlistScreen} />
          <Route exact path="/downloader/:setlistid" component={DownloadFilesScreen} />
          <Route exact path="/pdf/:file" render={(props) => (<DocPDF {...props} getNext={getNext} touchS={touchS} touchM={touchM} touchE={touchE} />)} />
          <Route exact path="/image/:file" render={(props) => (<DocImage {...props} getNext={getNext} touchS={touchS} touchM={touchM} touchE={touchE} />)} />
          <Route exact path="/audio/:file" render={(props) => (<DocAudio {...props} getNext={getNext} touchS={touchS} touchM={touchM} touchE={touchE} />)} />
          <Route exact path="/break/:breaker" render={(props) => (<DocBreak {...props} getNext={getNext} touchS={touchS} touchM={touchM} touchE={touchE} />)} />
          <Route exact path="/nofile/:fileid" render={(props) => (<DocMissing {...props} getNext={getNext} touchS={touchS} touchM={touchM} touchE={touchE} />)} />
          <Route exact path="/setlistedit/:setlistid" component={SetlistEditor} />
          <Route exact path="/songedit/:songid" component={SongEditor} />
        </Switch>
      </main>
    </Router>
  )
}

export default App

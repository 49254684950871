import { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logo from '../images/coflogo.png'
import { instruments } from './instruments'

import { setCurrentInstrument, setCurrentSetlist, setCurrentSetlistItem, setCurrentSetlistFiles, setEditMode, setShowAudio, setScribbleCanvas, setPDFRotate } from "../redux/actions/currentInfoActions"
import MyModal from '../components/modal/MyModal'


const Navbar = (props) => {
  const { getNext } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const [showInstrumentSelector, setShowInstrumentSelector] = useState(false)
  const [canShowAudio, setCanShowAudio] = useState(false)
  // const [showAudio, setShowAudio] = useState(false)

  const { currentPath, currentInstrument, currentSetlist, loginRole, currentSetlistItem, currentlyEditing, showAudio, scribbleCanvas, pdfRotate } = useSelector((state) => state.currentInfo)
  const { fools } = useSelector((state) => state.fools)

  useEffect(() => {
    if ((currentPath === "pdf" || currentPath === "missing") && currentSetlistItem && currentSetlist) {
      const thisSong = fools.songdata.find(s => s.songID === parseInt(currentSetlist.setlistItems[currentSetlistItem].substr(4)))
      if (thisSong) setCanShowAudio(fools.songfiledata.find(f => f.songID == thisSong.songID && f.songfileTypes.includes("Audio Recording")))
    }
    else {
      setCanShowAudio(false)
      dispatch(setShowAudio(false))
    }
  }, [currentSetlistItem, currentPath, fools])

  const iClick = (i) => {
    dispatch(setCurrentInstrument(i))
    dispatch(setEditMode(false))
    setShowInstrumentSelector(false)
  }
  const imgURL = currentInstrument ? instruments.find(i => i.name === currentInstrument).img : ''
  const instrumentIcon = currentInstrument ? instruments.find(i => i.name === currentInstrument).icon : ''

  return (
    <nav className="navbar  border-b-2 border-yellow-400 bg-gray-800">

      <ul className="navbarlist flex justify-items-start align-middle">
        <li className="mr-4">
          <Link to="/"><img src={logo} alt="Chain Of Fools logo" className="w-16 max-w-none m-2 md:w-20" /></Link>
        </li>
        <li>
          <Link to="/setlists" className="">
            <FontAwesomeIcon icon="list" size="3x" className="icon-img icon-button" />
          </Link>
        </li>
        <li>
          <Link to="/songs/all" className="" onClick={() => { dispatch(setCurrentSetlist(null)); dispatch(setCurrentSetlistFiles(null)) }}>
            <FontAwesomeIcon icon="music" size="3x" className="icon-img icon-button" />
          </Link>
        </li>
        <li className="mt-4 text-gray-200 animate-pulse" onClick={() => setShowInstrumentSelector(true)}>
          {/* <img id="currentinstrumentimg" src={imgURL} alt="Chosen weapon is {currentInstrument}" className="instrumentimg w-12 h-12 max-w-none mt-5 filter invert animate-pulse" onClick={() => setShowInstrumentSelector(true)} /> */}
          {instrumentIcon({ color: "text-yellow-400", size: "50" })}
        </li>
        <li>{currentSetlist ?
          (
            <FontAwesomeIcon icon="angle-double-up" size="4x" className="icon-img mt-2 text-gray-300" onClick={() => history.replace(currentSetlistItem ? "/songs/" + currentSetlist.setlistID : "/setlists")} />
          ) : (
            <FontAwesomeIcon icon="angle-double-up" size="4x" className="icon-img mt-2 disabled" />
          )
        }
        </li>
        <li>
          {currentSetlist && currentSetlistItem && currentSetlistItem > 0 ?
            (
              <div className="nextinfo">
                <FontAwesomeIcon icon="arrow-alt-circle-left" size="3x" className="icon-img text-gray-300" onClick={() => getNext("bwd", history)} />
                <div className='nexttitle text-center'>{currentSetlist.setlistItems[currentSetlistItem - 1].substring(0, 1) == "S" ? "Break" : fools.songdata.find(s => currentSetlist.setlistItems[currentSetlistItem - 1].substring(4) == s.songID)?.songName}</div>
              </div>
            ) : (
              <FontAwesomeIcon icon="arrow-alt-circle-left" size="3x" className="icon-img disabled" />
            )
          }
        </li>
        <li>
          {currentSetlist && currentSetlistItem !== null && currentSetlistItem < currentSetlist?.setlistItems.length - 1 ?
            (
              <div className="nextinfo">
                <FontAwesomeIcon icon="arrow-alt-circle-right" size="3x" className="icon-img text-gray-300" onClick={() => getNext("fwd", history)} />
                <div className='nexttitle text-center'>{currentSetlist.setlistItems[currentSetlistItem + 1].substring(0, 1) == "S" ? "Break" : fools.songdata.find(s => currentSetlist.setlistItems[currentSetlistItem + 1].substring(4) == s.songID)?.songName}</div>
              </div>
            ) : (
              <FontAwesomeIcon icon="arrow-alt-circle-right" size="3x" className="icon-img disabled" />
            )
          }
        </li>
        <li>
          {canShowAudio ?
            (showAudio ? (
              <FontAwesomeIcon icon="volume-up" size="3x" className="icon-img text-gray-300" onClick={() => dispatch(setShowAudio(!showAudio))} />
            ) : (
              <FontAwesomeIcon icon="volume-up" size="3x" className="icon-img text-gray-600" onClick={() => dispatch(setShowAudio(!showAudio))} />
            )
            ) : ('')
          }
        </li>
        <li>
          {currentPath === "pdf" ?
            (scribbleCanvas ? (
              <FontAwesomeIcon icon="pencil-alt" size="3x" className="icon-img text-gray-300" onClick={() => dispatch(setScribbleCanvas(!scribbleCanvas))} />
            ) : (
              <FontAwesomeIcon icon="pencil-alt" size="3x" className="icon-img text-gray-600" onClick={() => dispatch(setScribbleCanvas(!scribbleCanvas))} />
            )
            ) : ('')
          }
        </li>
        <li>
          {currentPath === "pdf" ?
            (
              <FontAwesomeIcon icon="retweet" size="3x" className="icon-img text-gray-300" onClick={() => dispatch(setPDFRotate(pdfRotate === 270 ? 0 : pdfRotate + 90))} />
            ) : ('')
          }
        </li>
        <li>
          {loginRole === 'admin' ?
            (currentlyEditing ? (
              <FontAwesomeIcon icon="edit" size="3x" className="icon-img text-gray-300" onClick={() => dispatch(setEditMode(!currentlyEditing))} />
            ) : (
              <FontAwesomeIcon icon="edit" size="3x" className="icon-img" onClick={() => dispatch(setEditMode(!currentlyEditing))} />
            )
            ) : ('')
          }
        </li>
      </ul>
      {showInstrumentSelector && (
        <MyModal clicker={() => setShowInstrumentSelector(false)}>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={() => setShowInstrumentSelector(false)}>
            <div className="relative w-auto my-6 mx-auto max-w-6x flex flex-col">
              <div className="border-0 rounded-lg shadow-lg relativew-full bg-white outline-none focus:outline-none p-10">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  Choose your weapon
                </h3>
                <div className=" flex flex-col md:flex-row ">
                  {instruments.map((i) => (
                    <div key={i.name} className="flex flex-row md:flex-col items-center" onClick={() => iClick(i.name)} src={i.img} alt={i.name}>
                      {/* <img className="instrumentselector h-12 w-12 flex-shrink" /> */}
                      {i.icon({ color: "text-gray-800", size: "50" })}
                      <span className=" text-sm text-gray-900 font-body">{i.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </MyModal>
      )}


    </nav >
  )
}

export default Navbar


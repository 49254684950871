import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from 'react-router-dom'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { arrayBufferToBase64 } from '../util/util'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactAudioPlayer from 'react-audio-player'
import CanvasDraw from 'react-canvas-draw'

import { setCurrentPath, setShowAudio, setScribbleCanvas, setCurrentSetlistItem } from "../redux/actions/currentInfoActions"
import useWindowWidth from "../util/useWindowWidth"

import Metronome from './Metronome'
import PrintFile from './PrintFile'
import { instruments } from './instruments'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const DocPDF = (props) => {
  const dispatch = useDispatch()
  const { currentInstrument, currentSetlist, currentSetlistItem, currentSetlistFiles, showAudio, scribbleCanvas, pdfRotate } = useSelector((state) => state.currentInfo)
  const { fools } = useSelector((state) => state.fools)

  const history = useHistory()

  const [numPages, setNumPages] = useState(null)
  const [cacheFile, setCacheFile] = useState('')
  const [audioCacheFile, setAudioCacheFile] = useState('')
  const [audioFile, setAudioFile] = useState(null)
  const [docTap, setDocTap] = useState(false)

  const [song, setSong] = useState({})
  const [songFilesList, setSongFilesList] = useState([])

  const { match, touchS, touchM, touchE } = props

  const songFile = decodeURIComponent(match.params.file)

  const width = useWindowWidth()

  const pageRefs = useRef([])
  pageRefs.current = []
  const overlayRefs = useRef([])
  overlayRefs.current = []
  const drawRef = useRef()
  const [scribbling, setScribbling] = useState(-1)
  const [brush, setBrush] = useState("rgba(255,240,20,0.5)")
  const [brushSize, setBrushSize] = useState(15)
  const [canvasData, setCanvasData] = useState("")
  const palletRef = useRef()
  let saver = null

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  const addToRefs = el => {
    if (el && !pageRefs.current.includes(el)) {
      pageRefs.current.push(el)
    }
  }
  const addToOverlayRefs = el => {
    if (el && !overlayRefs.current.includes(el)) {
      overlayRefs.current.push(el)
    }
  }

  const getFromCache = async (file) => {
    const cache = await caches.open('foolscache')
    const inCache = await cache.match(file)
    if (inCache) {
      const c = await cache.match(file)
      const c2 = await c.arrayBuffer()
      await setCacheFile('data:application/pdf;base64,' + arrayBufferToBase64(c2))
    }
    else {
      cache.add(file).then(async () => {
        const c = await cache.match(file)
        const c2 = await c.arrayBuffer()
        await setCacheFile('data:application/pdf;base64,' + arrayBufferToBase64(c2))
      })
    }
  }

  const getAudioFromCache = async (file) => {
    const cache = await caches.open('foolscache')
    const inCache = await cache.match(file)
    if (inCache) {
      const c = await cache.match(file)
      const c2 = await c.arrayBuffer()
      await setAudioCacheFile(URL.createObjectURL(new Blob([c2])))
    }
    else {
      cache.add(file).then(async () => {
        const c = await cache.match(file)
        const c2 = await c.arrayBuffer()
        await setAudioCacheFile(URL.createObjectURL(new Blob([c2])))
      })
    }
  }

  const makePrintDownload = () => {
    // console.log('Print button!')
    const a = document.createElement('a')
    a.href = cacheFile
    a.download = songFile.substr(7)
    a.click()
  }

  const startCanvas = (index) => {
    console.log("index", index)
    if (index === scribbling) {
      setScribbling(-1)
      drawRef.current.style.display = "none"
      // palletRef.current.style.display = "none"
      if (overlayRefs.current[index]) overlayRefs.current[index].style.display = "initial"
      return
    }
    setScribbling(index)
    console.log("overlay refs", overlayRefs)
    if (overlayRefs.current[index]) overlayRefs.current[index].style.display = "none"

    const pageW = pageRefs.current[index].clientWidth
    const pageH = pageRefs.current[index].nextSibling.clientHeight
    const canvasTop = pageRefs.current[index].getBoundingClientRect().bottom + window.scrollY
    drawRef.current.style.top = "" + canvasTop + "px"
    drawRef.current.style.width = "" + pageW + "px"
    drawRef.current.style.height = "" + pageH + "px"
    drawRef.current.style.display = "block"
    drawRef.current.style.touchAction = "none"
    // palletRef.current.style.display = "flex"
    if (localStorage.getItem(songFile + "_" + index + "_canvas")) {
      const canvasData = localStorage.getItem(songFile + "_" + index + "_canvas")
      console.log("loading", songFile + "_" + index + "_canvas", saver)
      setCanvasData(canvasData)
    }
  }

  const saveCanvas = () => {
    const newCanvasData = saver.getSaveData()
    console.log(newCanvasData)
    if (JSON.parse(newCanvasData)?.lines.length === 0) {
      localStorage.removeItem(songFile + "_" + scribbling)
      localStorage.removeItem(songFile + "_" + scribbling + "_canvas")
      setCanvasData("")
    }
    else {
      localStorage.setItem(songFile + "_" + scribbling, saver.getDataURL())
      localStorage.setItem(songFile + "_" + scribbling + "_canvas", newCanvasData)
    }
    drawRef.current.style.display = "none"
    // palletRef.current.style.display = "none"
    if (overlayRefs.current[scribbling]) overlayRefs.current[scribbling].style.display = "initial"
    setScribbling(-1)
    dispatch(setScribbleCanvas(false))
  }

  const setUnderline = (inx) => {
    const scribbleIcons = Array.from(document.querySelectorAll(".pallette div"))
    scribbleIcons.forEach(i => i.classList.remove("selected"))
    scribbleIcons[inx].classList.toggle("selected")
  }
  useEffect(() => {
    dispatch(setCurrentPath("pdf"))

    getFromCache(songFile)
    // console.log('docpdf', songFile)

  }, [songFile, dispatch])

  useEffect(() => {
    if (currentSetlist) {
      // const thisSong = fools.songdata.find(s => s.songID === parseInt(currentSetlist.setlistItems[currentSetlistItem].substr(4)))
      const thisSong = fools.songfiledata.find(f => f.songfileName == decodeURIComponent(window.location.hash.split('/')[2]))
      setSong(thisSong)
      // setSongFilesList(currentSetlistFiles)
      setSongFilesList(fools.songfiledata.filter(f => f.songID == thisSong.songID))
      const aFile = fools.songfiledata.find(f => f.songID == thisSong.songID && f.songfileTypes.includes("Audio Recording"))
      // console.log("audio search", "currentitem", currentSetlistItem, "should be", currentSetlist.setlistItems[currentSetlistItem], "song", song, "file", aFile)
      if (aFile) {
        setAudioFile(aFile.songfileName)
        getAudioFromCache(aFile.songfileName)
      }
      else {
        dispatch(setShowAudio(false))
      }
    }
    else {
      const thisSong = fools.songfiledata.find(f => f.songfileName == decodeURIComponent(window.location.hash.split('/')[2]))
      setSongFilesList(fools.songfiledata.filter(f => f.songID == thisSong.songID))
    }
  }, [currentSetlist, currentSetlistItem, fools.songdata])

  useEffect(() => { setScribbling(-1) }, [])
  useEffect(() => {
    console.log("scribbling", scribbleCanvas,)
    if (pageRefs) {
      if (scribbleCanvas) {
        setBrush("rgba(255,240,20,0.5)")
        setBrushSize(15)
        startCanvas(0)
      }
      else {
        if (overlayRefs.current[scribbling]) overlayRefs.current[scribbling].style.display = "initial"
        setScribbling(-1)
      }
    }

  }, [scribbleCanvas])

  const [renderAudio, setRenderAudio] = useState(false)
  useEffect(() => {
    if (showAudio) {
      setTimeout(() => {
        setRenderAudio(true)
      }, 200)
    } else {
      setRenderAudio(false)
    }
  }, [showAudio])
  return (
    <>
      <div onTouchStart={(e) => { if (!scribbleCanvas) touchS(e) }}
        onTouchMove={(e) => { if (!scribbleCanvas) touchM(e) }}
        onTouchEnd={(e) => { if (!scribbleCanvas) touchE(e, history) }}>
        <div className="metronome-holder">
          {currentSetlist && currentInstrument === "Drums" && (
            <Metronome tempo={song.songTempo?.toString()} size={100} autoStart="true" />
          )}
          {currentSetlist && currentInstrument !== "Drums" && (
            <Metronome tempo={song.songTempo?.toString()} size={100} />
          )}
        </div>
        {docTap && <div key="div" className="options-holder"><div className="" onClick={() => makePrintDownload()}><PrintFile /></div><div className="song_files flex">
          {songFilesList.map((f, index) => {
            const fileType = f.songfileName?.split('.').pop() === 'pdf' ? '/pdf/' : '/audio/'
            return (
              <Link key={f.songfileID} to={`${fileType}${encodeURIComponent(f.songfileName)}`} onClick={(e) => { if (currentSetlistItem) dispatch(setCurrentSetlistItem(currentSetlistItem)) }}>
                <span className="fileblock flex ml-1" link={f.songfileName}>{f.songfileTypes?.map((t, ind) => { const k = t + ind; return (<div key={k} alt={t} className="instrument-img p-0 m-0 text-xs" >{instruments.find(i => i.name === t).icon({ color: "text-gray-800", size: "27" })}</div>) })}</span>
              </Link>
            )
          })
          }
        </div></div>}
        {audioFile && showAudio && (
          <div className={`audio-holder ${renderAudio && 'show-audio'}`}>
            <ReactAudioPlayer
              src={audioFile}
              controls
              onError={(e) => alert(JSON.stringify(JSON.parse(e)))}
            />
          </div>)
        }
        {scribbleCanvas && (
          <div className="pallette" ref={palletRef}>
            <div className="palleticon selected"><FontAwesomeIcon icon="highlighter" title="Higlighter" onClick={() => { setUnderline(0); setBrush("rgba(255,240,20,0.5)"); setBrushSize(15) }} style={{ color: "#ffff20", marginRight: "4px" }} /><span>Highlighter</span></div>
            <div className='palleticon'><FontAwesomeIcon icon="pencil-alt" title="Black Pencil" onClick={() => { setUnderline(1); setBrush("#444"); setBrushSize(2) }} style={{ color: "#000", marginRight: "4px" }} /><span>Black Pencil</span></div>
            <div className='palleticon'><FontAwesomeIcon icon="pencil-alt" title="Red Pencil" onClick={() => { setUnderline(2); setBrush("#f22"); setBrushSize(2) }} style={{ color: "#d22", marginRight: "4px" }} /><span>Red Pencil</span></div>
            <div className='palleticon'><FontAwesomeIcon icon="undo" title="Undo" style={{ marginRight: "4px" }} onClick={() => { saver.undo() }} /><span>Undo Last</span></div>
            <div className='palleticon'><FontAwesomeIcon icon="eraser" title="Erase it all" style={{ color: "#d25637", marginRight: "4px" }} onClick={() => { saver.eraseAll() }} /><span>Remove All</span></div >
            <div className='palleticon'><FontAwesomeIcon icon="save" title="Save" style={{ color: "#26b54b" }} onClick={saveCanvas} /><span>Save Scribble</span></div>
          </div >)
        }
        <Document
          loading="Loading the PDF..."
          file={cacheFile}
          rotate={pdfRotate}
          noData="Just fetching that one..."
          onLoadSuccess={onDocumentLoadSuccess}
          onClick={() => { setDocTap(true); window.setTimeout(() => { setDocTap(false) }, 3000) }}
        >
          {
            Array.from(
              new Array(numPages),
              (el, index) => (
                <div key={index}>
                  <div ref={addToRefs} className="pdfpage" key={index}></div>
                  <div style={{ position: "relative", overflow: "hidden" }}>
                    <Page key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={Math.min(width * 0.95, 1200)}
                    />
                    {localStorage.getItem(songFile + "_" + index) ? (<p><img ref={addToOverlayRefs} className="overlay" src={localStorage.getItem(songFile + "_" + index)} />(scribble applied)</p>) : (<p className='overlay'>(no scribble)</p>)}
                  </div>
                </div>
              ),
            )
          }
        </Document>
        <div ref={drawRef} style={{ position: "absolute", zIndex: 2000, top: 0 }}>
          {scribbling >= 0 && (
            <CanvasDraw ref={c => saver = c} style={{
              backgroundColor: "transparent",
              width: "100%",
              height: "100%"
            }}
              brushColor={brush} brushRadius={brushSize} lazyRadius={0} saveData={canvasData}
            />
          )}
        </div>
      </div >
    </>
  )
}

export default DocPDF
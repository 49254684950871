import { useState } from 'react'
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { setCurrentSetlistItem } from "../redux/actions/currentInfoActions"

import { setlistBreaks } from './setlistbreaks'
import { ConditionalWrapper, secondsToTime } from '../util/util'
import axios from 'axios'
import spinner from '../images/loading.svg'

const SetlistBreak = ({ breaker, currentind, timeahead, songsahead }) => {
  const { currentSetlist, currentlyEditing } = useSelector((state) => state.currentInfo)
  const currentSetlistID = window.location.href.split("/").pop()

  // if (!currentSetlist) {
  //   console.log("SetlistBreak - no setlist set!", window.location.href)
  // }
  // else {
  //   console.log("SetlistBreak - Yes, I have a setlist set!", window.location.href.split("/").pop())
  // }
  const history = useHistory()
  const dispatch = useDispatch()

  const breakClass = breaker.substr(0, 2) === "SP" ? "setlist-break newpage" : "setlist-break"

  const [downloading, setDownloading] = useState(false)

  const downloadPDF = async (setlistID) => {
    setDownloading(true)
    const res = await axios({ url: "/api/pdfsetlist/" + setlistID.toString(), method: "post", responseType: "blob" })
    // console.log("pdf response", res.headers["content-disposition"].split("=")[1])
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', res.headers["content-disposition"].split("=")[1])
    document.body.appendChild(link)
    link.click()
    link.remove()
    setDownloading(false)
  }

  return <div className="song">
    <ConditionalWrapper condition={breaker.substr(0, 2) !== "ST"} wrapper={children => <Link to={`/break/${breaker}`} onClick={(e) => { dispatch(setCurrentSetlistItem(currentind)) }}>{children}</Link>}>
      <div className={breakClass}>
        <p className="font-hdr text-4xl">{breaker.substr(0, 2) === "ST" ? breaker.substr(2).split(':')[0] : setlistBreaks[breaker.substr(0, 2)]}{breaker.substr(0, 2) === "ST" && currentlyEditing ? <FontAwesomeIcon icon="edit" className="text-sm ml-2 text-gray-300" onClick={() => history.push('/setlistedit/' + currentSetlist.setlistID)} /> : ''}</p>
        {breaker.substr(0, 2) === "ST" ?
          <><div className="flex flex-col text-center cursor-pointer" onClick={(e) => { downloadPDF(currentSetlistID) }}><FontAwesomeIcon icon="file-pdf" className="text-red-500 text-3xl ml-2" /><p className="text-sm">PDF Print</p></div>
            {downloading && <img src={spinner} className="spinner width-10 height-10 mr-2" alt="loading" height={20} width={20} />}
            <div className="download-button"><Link to={"/downloader/" + currentSetlistID}><p className="flex flex-col text-center"><FontAwesomeIcon icon="cloud-download-alt" className="text-xl ml-2" /><span className="text-sm">download my files</span></p></Link></div></>
          : ''}

        {breaker !== 'SP' && <p className="break__info flex flex-col text-right"><span><span className="text-sm">time </span><span className="text-lg">{secondsToTime(timeahead)}</span></span><span><span className="text-lg">{songsahead}</span><span className="text-sm"></span> songs</span></p>}
      </div>
    </ConditionalWrapper>
  </div>
}

export default SetlistBreak
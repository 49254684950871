import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { instruments, transpose } from './instruments'
import { ConditionalWrapper } from "../util/util"
import { setCurrentSetlistItem } from "../redux/actions/currentInfoActions"
import { deleteSongData } from "../redux/actions/foolsActions"

const Song = ({ songID, songName, songArtist, songTime, songTempo, songKey, songfileIDs, setlistID, setlistItem }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { fools } = useSelector((state) => state.fools)
  const { currentInstrument, currentlyEditing, currentSetlist } = useSelector((state) => state.currentInfo)

  const currentTranspose = instruments.find(i => i.name === currentInstrument).transpose

  let order = []
  instruments.map(i => order[i.order] = i.name)

  var filesList = []
  filesList = fools.songfiledata.filter(f => f.songID === songID)
  filesList.sort((a, b) => { return order.indexOf(a.songfileTypes[0]) > order.indexOf(b.songfileTypes[0]) ? 1 : -1 })

  // console.log('song.js songID', songID)
  // console.log('song.js files', filesList)
  const defaultFile = fools.songfiledata.find((f) => (f.songID === songID) && f.songfileTypes.includes(currentInstrument))
  let defaultPath = ''
  if (defaultFile === undefined) {
    if (currentSetlist) {
      defaultPath = `/nofile/${currentSetlist.setlistItems[setlistItem]}`
    }
    else {
      defaultPath = `/nofile/song${songID}`
    }
  }
  else {
    const defaultExt = defaultFile ? defaultFile.songfileName?.split('.').pop() : ''
    defaultPath = defaultExt === 'pdf' ? `/pdf/${encodeURIComponent(defaultFile.songfileName)}` : `/audio/${encodeURIComponent(defaultFile.songfileName)}`
  }
  // console.log('defaultPath', defaultPath)

  const deleteSong = async () => {
    //remove song from songdata array in fools
    //dispatch update to data
    if (window.confirm(`Are you sure you want to PERMANENTLY DELETE this song? `)) {
      // console.log('click deleteing ', songID)
      const newFools = fools
      newFools.songdata = newFools.songdata.filter((s) => s.songID !== parseInt(songID))
      await dispatch(deleteSongData(songID, newFools))
    }
  }

  return (
    <div className="song-item w-300 flex-col mx-auto text-gray-900">

      <Link to={defaultPath} onClick={(e) => { if (setlistItem) dispatch(setCurrentSetlistItem(setlistItem)) }}>
        <div className="song_title flex justify-between text-2xl font-hdr">    <p className="song-name">{songName}</p>
          {currentlyEditing && (
            <span className="song-edit-links w-16">
              <FontAwesomeIcon icon="edit" className="text-lg w-4 mr-4 text-yellow-600" onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push('/songedit/' + songID.toString()) }} />
              <FontAwesomeIcon icon="minus-circle" className="text-lg w-4 text-red-600" onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteSong() }} />
            </span>
          )}
          <p className="song_artist text-right">{songArtist}</p>
        </div>
      </Link>

      <div className="song_details flex justify-between">
        <div className="song_info flex">
          <p className="song_time mr-1"><FontAwesomeIcon icon="history" /> {songTime}</p>
          <p className="song_tempo mr-1"><FontAwesomeIcon icon="tachometer-alt" /> {songTempo}bpm</p>
          <p className="song_key"><FontAwesomeIcon icon="music" /> {songKey} <span className="transpose ml-1">{currentTranspose !== 0 ? transpose(songKey, currentTranspose) : ''}</span></p>
        </div>
        <div className="song_files flex">
          {filesList?.map((f, index) => {
            const fileType = f.songfileName?.split('.').pop() === 'pdf' ? '/pdf/' : '/audio/'
            return (
              <Link key={f.songfileID} to={`${fileType}${encodeURIComponent(f.songfileName)}`} onClick={(e) => { if (setlistItem) dispatch(setCurrentSetlistItem(setlistItem)) }}>
                <span className="fileblock flex ml-1" link={f.songfileName}>{f.songfileTypes?.map((t, ind) => { const k = t + ind; return (<div key={k} alt={t} className="instrument-img p-0 m-0 text-xs" >{instruments.find(i => i.name === t).icon({ color: "text-gray-800", size: "27" })}</div>) })}</span>
              </Link>
            )
          })}
        </div>
      </div>
    </div >
  )
}

export default Song

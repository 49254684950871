export const GET_DATA_REQUEST = "GET_DATA_REQUEST"
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS"
export const GET_DATA_FAIL = "GET_DATA_FAIL"
export const SET_SETLISTS_REQUEST = "SET_SETLISTS_REQUEST"
export const SET_SETLISTS_SUCCESS = "SET_SETLISTS_SUCCESS"
export const SET_SETLISTS_FAIL = "SET_SETLISTS_FAIL"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const SET_SONGS_REQUEST = "SET_SONGS_REQUEST"
export const SET_SONGS_SUCCESS = "SET_SONGS_SUCCESS"
export const SET_SONGS_FAIL = "SET_SONGS_FAIL"
export const SET_UPLOAD_REQUEST = "SET_UPLOAD_REQUEST"
export const SET_UPLOAD_SUCCESS = "SET_UPLOAD_SUCCESS"
export const SET_UPLOAD_DONE = "SET_UPLOAD_DONE"
export const SET_UPLOAD_FAIL = "SET_UPLOAD_FAIL"
export const SET_SONGFILES_REQUEST = "SET_SONGFILES_REQUEST"
export const SET_SONGFILES_SUCCESS = "SET_SONGFILES_SUCCESS"
export const SET_SONGFILES_FAIL = "SET_SONGFILES_FAIL"

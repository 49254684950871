import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const DocImage = ({ match, touchS, touchM, touchE }) => {
    const history = useHistory()

    const [cacheFile, setCacheFile] = useState('')

    console.log('image document page')
    const songFile = decodeURIComponent(match.params.file)

    console.log('1', songFile)

    const getFromCache = async (file) => {
        const cache = await caches.open('foolscache')
        const inCache = await cache.match(file)
        if (inCache) {
            console.log('2. in cache')
            const c = await cache.match(file)
            const c2 = await c.arrayBuffer()
            setCacheFile(URL.createObjectURL(new Blob([c2])))
        }
        else {
            console.log('3. not in cache, getting')
            cache.add(file).then(async () => {
                const c = await cache.match(file)
                const c2 = await c.arrayBuffer()

                setCacheFile(URL.createObjectURL(new Blob([c2])))
            })
        }
    }
    useEffect(() => {
        getFromCache(songFile)
    }, [songFile])

    return (
        <div className="flex align-center justify-center"
        onTouchStart={touchS}
        onTouchMove={touchM}
        onTouchEnd={(e) => touchE(e, history)}>
              <img id="pageimage" src={cacheFile} alt={songFile} />
        </div >
    )
}

export default DocImage
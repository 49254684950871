export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;


export const timeToSeconds = (time) => {
  const timeSplit = time.split(':')
  return parseInt(timeSplit[1]) + parseInt((timeSplit[0]) * 60)
}

export const secondsToTime = (timeIn) => {
  const hours = Math.floor(timeIn / 3600)
  const minutes = Math.floor(timeIn % 3600 / 60)
  const seconds = timeIn % 60

  return '' + (hours > 0 ? hours : '') + (hours > 0 ? ':' : '') + (hours > 0 ? ('00' + minutes).substr(-2) : minutes) + ':' + ('00' + seconds).substr(-2)
}

export const timeAhead = (songList, start, stop) => {
  let timeAhead = 0
  let songsAhead = 0
  for (let i = start; i < songList.length; i++) {
    if (('' + songList[i].songID).substr(0, 1) !== 'S') {
      timeAhead += timeToSeconds(songList[i].songTime)
      songsAhead++
    }
  }
  return { timeAhead, songsAhead }
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const datex = (dtString) => {
  const dY = dtString.substr(0, 4)
  const dM = dtString.substr(5, 2) - 1
  const dD = dtString.substr(8, 2)
  const dTH = dtString.substr(11, 2)
  const dTM = dtString.substr(14, 2)
  const newD = new Date(dY, dM, dD, dTH, dTM)
  return newD.toLocaleDateString(undefined, { day: 'numeric', weekday: 'short', month: 'short', year: 'numeric' }) + ' ' + newD.toLocaleTimeString(undefined, { timeStyle: 'short' })
}

// returns approximate size of a single cache (in bytes)
function cacheSize(c) {
  return c.keys().then(a => {
    return Promise.all(
      a.map(req => c.match(req).then(res => res.clone().blob().then(b => b.size)))
    ).then(a => a.reduce((acc, n) => acc + n, 0));
  });
}

// returns approximate size of all caches (in bytes)
export const cachesSize = async () => {
  return caches.keys().then(async a => {
    return Promise.all(
      a.map(n => caches.open(n).then(c => cacheSize(c)))
    ).then(a => a.reduce((acc, n) => acc + n, 0));
  });
}
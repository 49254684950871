import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { setCurrentSetlistItem } from '../redux/actions/currentInfoActions'

import ReactAudioPlayer from 'react-audio-player'
import { instruments } from './instruments'

const DocAudio = ({ match, touchS, touchM, touchE }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { fools } = useSelector((state) => state.fools)
  const { currentSetlistItem, currentSetlistFiles } = useSelector((state) => state.currentInfo)

  const [cacheFile, setCacheFile] = useState('')
  const [docTap, setDocTap] = useState(false)
  const [songFilesList, setSongFilesList] = useState([])

  const songFile = decodeURIComponent(match.params.file)

  const getFromCache = async (file) => {
    const cache = await caches.open('foolscache')
    const inCache = await cache.match(file)
    if (inCache) {
      const c = await cache.match(file)
      const c2 = await c.arrayBuffer()
      setCacheFile(URL.createObjectURL(new Blob([c2])))
    }
    else {
      cache.add(file).then(async () => {
        const c = await cache.match(file)
        const c2 = await c.arrayBuffer()

        setCacheFile(URL.createObjectURL(new Blob([c2])))
      })
    }
  }
  useEffect(() => {
    getFromCache(songFile)
    const thisSong = fools.songfiledata.find(f => f.songfileName == songFile)
    setSongFilesList(fools.songfiledata.filter(f => f.songID == thisSong.songID))

  }, [songFile])

  return (
    <div className="flex align-center justify-center mt-5"
      onTouchStart={touchS}
      onTouchMove={touchM}
      onTouchEnd={(e) => touchE(e, history)}
      onClick={() => { setDocTap(true); window.setTimeout(() => { setDocTap(false) }, 3000) }}>
      {docTap && <div className="options-holder"><div className="song_files flex">
        {songFilesList.map((f, index) => {
          const fileType = f.songfileName?.split('.').pop() === 'pdf' ? '/pdf/' : '/audio/'
          return (
            <Link key={f.songfileID} to={`${fileType}${encodeURIComponent(f.songfileName)}`} onClick={(e) => { if (currentSetlistItem) dispatch(setCurrentSetlistItem(currentSetlistItem)) }}>
              <span className="fileblock flex ml-1" link={f.songfileName}>{f.songfileTypes?.map((t, ind) => { const k = t + ind; return (<div key={k} alt={t} className="instrument-img p-0 m-0 text-xs" >{instruments.find(i => i.name === t).icon({ color: "text-gray-800", size: "27" })}</div>) })}</span>
            </Link>
          )
        })}
      </div></div>}

      <ReactAudioPlayer
        src={songFile}
        autoPlay
        controls
      />
    </div >
  )
}

export default DocAudio
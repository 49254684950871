import React from 'react'
import { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Song from "../components/Song"
import SetlistBreak from "../components/SetlistBreak"
import { setCurrentSetlist, setCurrentSetlistFiles, setCurrentSetlistItem, setCurrentPath } from "../redux/actions/currentInfoActions"

import { timeToSeconds } from '../util/util'
import spinner from "../images/loading.svg"

const SongScreen = ({ match, history }) => {
  const { fools, loading, saving, error } = useSelector((state) => state.fools)
  const { currentSetlist, currentSetlistItem, currentlyEditing, currentPath } = useSelector((state) => state.currentInfo)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPath("songs"))
    if (currentSetlistItem && currentSetlist) {
      //scroll down to setlist item
      const currentSongList = Array.from(document.querySelectorAll("div.song"))
      // console.log("return to song items from", currentSetlistItem, currentSongList)
      currentSongList[currentSetlistItem + 1].querySelector("p.song-name")?.classList.add("song-current")
      currentSongList[currentSetlistItem].scrollIntoView()
    }
    dispatch(setCurrentSetlistItem(null))
  }, [])

  let songList = []
  const songRefs = useRef([])

  if (match.params.setlistid === 'all') {
    songList = fools.songdata
  } else {
    const setlistItems = fools.setlistdata.find((s) => s.setlistID === parseInt(match.params.setlistid)).setlistItems
    const setlistName = fools.setlistdata.find((s) => s.setlistID === parseInt(match.params.setlistid)).setlistName
    const songShortList = fools.songdata.filter((s) => setlistItems.includes('song' + s.songID))
    songList.push({ songID: "ST" + setlistName })
    setlistItems.forEach((s) => {
      if (s.substring(0, 4) === 'song') {
        const sid = s.substr(4)
        songList.push(songShortList.find((x) => x.songID === parseInt(sid)))
      }
      else {
        songList.push({ songID: s })
      }
    })
  }
  songRefs.current = songList.map((_, i) => songRefs.current[i] ?? React.createRef())

  // handle what happens on key press
  const handleKeyPress = useCallback((event) => {
    const sid = songList.findIndex(s => s.songID.toString().substr(0, 1) !== "S" && s.songName.substr(0, 1).toLowerCase() === event.key.toLowerCase())
    if (sid > -1) {
      songRefs.current[sid].current.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [])

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)
    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
    // }
  }, [handleKeyPress])

  if (loading) return <h1 className="text-4xl text-white font-hdr uppercase mt-10 text-center">Loading...</h1>
  return (
    <>
      {fools?.setlistdata.length < 1 ? (
        <h1 className="text-4xl text-white font-hdr uppercase mt-10 text-center">No Data !!</h1>
      ) : (
        <>
          <div className="flex justify-center align-bottom text-gray-200">
            {error && (
              <span className="flex mr-2">
                <FontAwesomeIcon icon="exclamation-triangle" className="text-yellow-400 mr-1" />{" "}
                An error occured: {error}
              </span>
            )}
            {saving && (
              <span className="flex mr-2">
                <img src={spinner} className="spinner width-10 height-10 mr-2" alt="loading" height={20} width={20} />
                Deleting...
              </span>
            )}
            {currentlyEditing && match.params.setlistid === 'all' && (<span className="text-xl text-green-600 my-2 cursor-pointer" onClick={(e) => { e.preventDefault(); history.push("/songedit/new") }}><FontAwesomeIcon icon="plus-square" className="mr-1" />Add a new Song</span>)}
          </div>
          <div>
            {songList.map((song, ind) => {
              const isBreak = ('' + song.songID).substr(0, 1)
              if (isBreak === "S") {
                if (song.songID.substr(0, 2) === "ST") {
                  let timeAhead = 0
                  let songsAhead = 0
                  for (let i = ind + 1; i < songList.length; i++) {
                    if (('' + songList[i].songID).substr(0, 1) !== 'S') {
                      timeAhead += timeToSeconds(songList[i].songTime)
                      songsAhead++
                    }
                  }
                  songList[0].songID += `:${timeAhead}:${songsAhead}`

                  return <SetlistBreak key={song.songID} currentind={ind - 1} breaker={song.songID} timeahead={timeAhead} songsahead={songsAhead} />
                }
                else {
                  //read ahead in songList adding up time up to next break
                  let timeAhead = 0
                  let songsAhead = 0
                  for (let i = ind + 1; i < songList.length; i++) {
                    if (('' + songList[i].songID).substr(0, 1) === 'S') break
                    timeAhead += timeToSeconds(songList[i].songTime)
                    songsAhead++

                  }
                  songList[ind].songID += `:${timeAhead}:${songsAhead}`
                  return (
                    <SetlistBreak key={song.songID} currentind={ind - 1} breaker={song.songID} timeahead={timeAhead} songsahead={songsAhead} />
                  )
                }
              }
              else {
                return (
                  <div className="song" key={song.songID} ref={songRefs.current[ind]} >
                    <Song songID={song.songID} songName={song.songName} songArtist={song.songArtist} songTime={song.songTime} songTempo={song.songTempo} songKey={song.songKey} songFileIDs={song.songFileIDs} setlistID={currentSetlist} setlistItem={ind - 1} />
                  </div>
                )
              }
            })}

          </div>
        </>)
      }
    </>
  )
}

export default SongScreen

import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

// Reducers
import { foolsReducer } from "./reducers/foolsReducers"
import { currentInfoReducer } from "./reducers/currentInfoReducers"

const reducer = combineReducers({
  currentInfo: currentInfoReducer,
  fools: foolsReducer,
})

const middleware = [thunk]

const foolsDataInLocalStorage = localStorage.getItem("fools")
  ? JSON.parse(localStorage.getItem("fools")).fools
  : { "lastrefresh": null, "songdata": [], "songfiledata": [], "setlistdata": [], "gigdata": [] }

const currentInfoDataInLocalStorage = localStorage.getItem("foolsInfo")
  ? JSON.parse(localStorage.getItem("foolsInfo"))
  : { "isLoggedIn": false, "loginExpiry": null, "loginRole": "user", "firstTime": false, "currentPath": "home", "currentInstrument": "Lyrics", "counter": 0, "currentlyEditing": false, }

console.log("current state", currentInfoDataInLocalStorage)
const INITIAL_STATE = {
  currentInfo: {
    isLoggedIn: false,
    loginExpiry: currentInfoDataInLocalStorage.loginExpiry,
    loginRole: currentInfoDataInLocalStorage.loginRole,
    firstTime: false,
    currentInstrument: currentInfoDataInLocalStorage.currentInstrument,
    counter: 0,
    currentlyEditing: false,
    currentPath: "home",
    showAudio: false,
    scribbleCanvas: false,
    pdfRotate: 0,
  },
  fools: {
    fools: foolsDataInLocalStorage,
    loading: false,
    error: null,
  },
}
console.log("initial state", INITIAL_STATE)

const store = createStore(
  reducer,
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store

import { AltoIcon } from '../icons/AltoIcon'
import { TenorIcon } from '../icons/TenorIcon'
import { BariIcon } from '../icons/BariIcon'
import { ChordsIcon } from '../icons/ChordsIcon'
import { DrumsIcon } from '../icons/DrumsIcon'
import { LyricsIcon } from '../icons/LyricsIcon'
import { TromboneIcon } from '../icons/TromboneIcon'
import { TrumpetIcon } from '../icons/TrumpetIcon'
import { SpeakerIcon } from '../icons/SpeakerIcon'

import Alto from '../images/Alto.png'
import Tenor from '../images/Tenor.png'
import Bari from '../images/Bari.png'
import Chords from '../images/Chords.png'
import Drums from '../images/Drums.png'
import Lyrics from '../images/Lyrics.png'
import Trombone from '../images/Trombone.png'
import Trumpet from '../images/Trumpet.png'
import Speaker from '../images/Speaker.png'


export const instruments = [
    { "name": 'Lyrics', img: Lyrics, transpose: 0, icon: LyricsIcon, order: 0 },
    { "name": 'Chords', img: Chords, transpose: 0, icon: ChordsIcon, order: 1 },
    { "name": 'Alto', img: Alto, transpose: -3, icon: AltoIcon, order: 2 },
    { "name": 'Tenor', img: Tenor, transpose: 2, icon: TenorIcon, order: 3 },
    { "name": 'Bari', img: Bari, transpose: -3, icon: BariIcon, order: 4 },
    { "name": 'Trumpet', img: Trumpet, transpose: 2, icon: TrumpetIcon, order: 5 },
    { "name": 'Trombone', img: Trombone, transpose: 0, icon: TromboneIcon, order: 6 },
    { "name": 'Drums', img: Drums, transpose: 0, icon: DrumsIcon, order: 7 },
    { "name": 'Audio Recording', img: Speaker, transpose: 0, icon: SpeakerIcon, order: 8 }
]

export const transpose = (concert, transpose) => {

    if (transpose === 0) return ''

    const keys = ['A', 'Bb', 'B', 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab']
    const mode = concert?.substr(-1) === 'm' ? 'm' : ''
    const rootKey = mode === 'm' ? concert?.substr(0, concert.length - 1) : concert

    const keyPos = keys.indexOf(rootKey)
    let newKey = keyPos + transpose
    if (newKey < 0) newKey += 12
    if (newKey > 11) newKey -= 12

    return '[' + keys[newKey] + mode + ']'
}
import { useCallback, useState, useEffect } from 'react';

 function useEventListener(element, type, listener) {
  useEffect(() => {
    if (!element) {
      return undefined;
    }

    element.addEventListener(type, listener);

    return () => {
      element.removeEventListener(type, listener);
    };
  }, [element, type, listener]);
}

/**
 * Returns the interior width of the window in pixels.
 *
 * @returns {number} Width of the window in pixels
 */
export default function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getWindowWidth = useCallback(
    () => setWindowWidth(window.innerWidth),
    [],
  );

  useEventListener(window, 'resize', getWindowWidth);

  return windowWidth;
}